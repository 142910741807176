@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.tableBodyRoot {
  overflow-y: auto;

  &::-webkit-scrollbar {
    max-height: calc(100% - 10px);
    padding: 10px 0;
    width: 6px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollTrackWhite;
    border-radius: 50px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbGrey;
    border-radius: 50px;
    width: 6px;
  }
}
