@font-face {
  font-family: 'Raleway';
  src: local('Raleway Regular'), local('Raleway-Regular'), url('../fonts/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verlag';
  src: local('Verlag Book'), local('Verlag-Book'), url('../fonts/Verlag-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verlag';
  src: local('Verlag Bold'), local('Verlag-Bold'), url('../fonts/Verlag-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
