@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  background: $white;
  border-radius: 10px;
  padding: 28px 30px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  .title {
    color: $black;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.777778px;
    margin-bottom: 1.75rem;
  }

  .subtitle {
    color: $black;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: -0.04863rem;
  }

  .tableContainer {
    min-height: unset;
    max-height: 55vh;
  }

  .tableContainerSticky {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: transparent;

    &::-webkit-scrollbar {
      width: 5px;
      border: none;
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: $scrollTrackWhite;
      border-left: 1px solid $silkGrey;
      border-right: 1px solid $silkGrey;
      border-radius: 50px;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollThumbGrey;
      border-radius: 50px;
      width: 6px;
    }
  }

  .tHeadClass {
    border-top: none;

    .tHeadCellClass {
      border-top: 1px solid $softWhite;
      height: 36px;
      padding: 14px 6px 12px;
      background-color: $white;
    }

    .tHeadCellClassSticky {
      position: sticky;
    }

    .tHeadLabelClass {
      font-size: 13px;
      line-height: 1.2;
    }

    .tHeadTopColumnCellClass {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.777778px;
      padding-top: 10px;
      padding-bottom: 0px;
      padding: 10px 20px 0;
      background-color: $white;
      transition: padding $timeFast ease-in-out;
    }

    .tHeadTopColumnCellWithAsideOpenClass {
      padding: 10px 16px 0;
    }

    @media screen and (min-width: 1440px) and (max-width: 1600px) {
      .tHeadTopColumnCellClass,
      .tHeadTopColumnCellWithAsideOpenClass {
        padding: 10px 6px 0;
      }
    }
  }

  .tBodyRowClass {
    &:nth-of-type(even) {
      background-color: rgba(7, 113, 134, 0.1);
    }
  }

  .tBodyCellClass {
    padding: 7px 6px;
    height: 36px;
  }

  .tableCellText {
    font-size: 13px;
    line-height: 1.2;
  }

  .tableTransactionDate {
    font-size: 15px;
    line-height: 1.2;
  }

  .noPaginationWrapperClass {
    padding-bottom: 0;
  }
}

.loaderWrapper {
  @include flex(row, center, center, nowrap);
  width: 100%;
  padding: 6px 0 6px;
}

.loadMoreButton {
  @include btnReset();
  font-family: 'Verlag', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  display: block;
  text-align: center;
  color: $turtleGreen;
  cursor: pointer;
  width: 100%;
  padding: 20px 16px 21px;
  margin: 0 auto;
  min-height: 62px;
}

.fadeEnter {
  opacity: 0;
}
.fadeExit {
  opacity: 1;
}
.fadeEnterActive {
  opacity: 1;
}
.fadeExitActive {
  opacity: 0;
}

.fadeEnterActive,
.fadeExitActive {
  transition: opacity 150ms;
}
