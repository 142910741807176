@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  .tHeadCellClass span {
    white-space: pre-wrap;
  }

  .tHeadCellClass,
  .detailsPathLinkClass,
  .tBodyCheckboxCellClass {
    padding: 11px 4px;
  }

  .checkboxRoot {
    padding: 0 0 3px;
  }

  .filterContainer {
    width: 330px;
  }

  .fileTypeWrapper {
    margin-right: 16px;
  }

  .filterBtn {
    padding: 0 25px;
    min-width: 113px;
  }
}

.contentWrapperHasMore {
  padding: 30px 30px 0;
}

.titleWrapper {
  @include flex(row, space-between, center, nowrap);
  margin-bottom: 16px;

  .header {
    font-size: 28px;
  }
}

.disabledDownloadButton {
  opacity: 0.7;
  pointer-events: none;
}

.dateAndFilterWrapper {
  @include flex(row, space-between, center, nowrap);

  .clearFiltersBtn {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
    padding: 10px;
    transition: all $timeFast ease-in-out;
    margin-right: 24px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.downloadContractsWrapper {
  @include flex(row, space-between, center, nowrap);
  padding-left: 18px;
  margin-bottom: 16px;

  .downloadButton {
    @include flex(row, flex-start, center, nowrap);

    & > img {
      margin-right: 10px;
    }
  }
}

.downloadContractsBidsWrapper {
  padding-left: 4px;
}

.detailsWrapper {
  padding: 20px 10px 20px 78px;
  width: 100%;

  .detailsTitle {
    font-weight: 700;
    margin-bottom: 16px;
  }

  .detailsText {
    font-size: 15px;
    color: $black;
    line-height: 1;
  }

  .dataList {
    @include ulReset();
    @include flex(column, flex-start, flex-start, wrap);

    max-height: 100px;
  }

  .dataListItem {
    @include flex(row, space-between, center, nowrap);

    width: 255px;
    max-height: 25px;

    &:not(:nth-child(3n)) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:nth-last-child(-n + 2) {
      width: 300px;
    }

    &:nth-last-child(2),
    &:nth-last-child(1) {
      .valueBox {
        min-width: 175px;
      }
    }

    &:nth-last-child(1) {
      .valueBox {
        @include flex(row, center, center, nowrap);
      }
    }

    &:nth-child(1) {
      .valueBox {
        min-width: 120px;
      }
    }

    &:nth-child(2) {
      .valueBox {
        min-width: 175px;
      }
    }

    &:nth-child(3) {
      @include flex(row, flex-start, center, nowrap);

      .label {
        margin-right: 10px;
      }

      .valueBox {
        min-width: 40px;
        padding: 0 6px;

        @include flex(row, center, center, nowrap);
      }
    }

    .label {
      white-space: nowrap;
    }

    .currencyBox,
    .valueBox {
      background: $datePickerWhite;
      border-radius: 5px;
      max-height: 25px;
      height: 25px;
    }

    .currencyInner {
      @include flex(row, flex-start, center, nowrap);
    }

    .currencyBox {
      min-width: 40px;
      padding: 0 6.5px;
      margin-right: 5px;

      @include flex(row, center, center, nowrap);
    }

    .currencyValueWrapper {
      width: 130px;
      max-width: 130px;
    }

    .valueBox {
      padding: 0 10px;

      @include flex(row, center, center, nowrap);
    }
  }

  .currencyListItem {
    width: 275px;

    @include flex(row, space-between, center, nowrap);

    .label {
      margin-right: 0;
    }
  }
}

.uncheckedBox {
  border: 1px solid $uncheckedCheckboxGrey;
}

.checkedBox,
.uncheckedBox {
  width: 12px;
  height: 12px;

  svg {
    width: 10px;
    height: 11px;
  }
}

.accordionIconRoot {
  margin: 0 15px;
}

.textInfo {
  font-size: 15px;
  text-transform: capitalize;
  color: $black;
  white-space: nowrap;
}

.relativeValue {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: $turtleGreen;
}

.flagWrapper {
  @include flex(row, flex-start, center, nowrap);

  cursor: pointer;

  img {
    width: 20px;
    min-width: 20px;
  }

  .platformText {
    margin-left: 10px;
  }
}

.amountInvestedAvailable {
  @include flex(row, center, center, nowrap);
}

.scoreA {
  color: $woodenGreyLight;
}

.scoreB {
  color: $woodenGrey;
}

.scoreC {
  color: $yellowSand;
}

.scoreD {
  color: $corall;
}

.scoreE {
  color: $darkRed;
}

.scoreF {
  color: $disabledGrey;
}

.status1 {
  color: $currentInvestment;
}

.status2 {
  color: $completedInvestment;
}

.status3 {
  color: $lateInvestment;
}
