@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.selectArrow {
  div {
    @include flex(row, center, center, nowrap);
    width: 20px;
    height: 17px;
  }

  svg {
    max-width: 16px;
  }
}
