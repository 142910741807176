@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.btn {
  @include btnReset();
  @include flex(row, center, center, nowrap);

  min-height: 35px;
  background: $turtleGreen;
  color: $white;
  border-radius: 30px;
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
}

.btnDisabled {
  cursor: default;
  background-color: $grey;
}

.beforeImg {
  width: 11px;
  height: 11px;
  margin-right: 12px;
}
