@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.content {
  width: 100%;
}

.headBlock {
  @include flex(row, space-between, center, nowrap);
  width: 100%;
  background: $white;
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 24px;

  .buttonBack {
    font-size: 15px;
    line-height: 21px;
    color: $black42;
  }

  .uniqIdLabelClass {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
  }
}

.detailsContent {
  @include flex(column, flex-start, flex-start, nowrap);
  gap: 16px;
  width: 100%;
  background: $white;
  border-radius: 10px;
  padding: 24px;
}

.detailsBlock {
  @include flex(row, flex-start, flex-start, nowrap);
  width: 100%;
  column-gap: 16px;
}

.column {
  @include flex(column, flex-start, flex-start, nowrap);
  row-gap: 16px;
  width: 100%;
}
