@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.btn {
  @include btnReset();
  @include flex(row, center, center, nowrap);

  padding: 0 58px;
  min-width: 160px;
  height: 35px;
  border: 2px solid $black42;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.4;
  text-transform: capitalize;
  color: $black42;
  white-space: nowrap;
  transition: all $timeFast ease-in-out;

  &:hover {
    background: rgba(15, 25, 66, 0.07);
  }
}
