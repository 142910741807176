@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(row, flex-start, center, nowrap);

  max-width: 200px;
  max-height: 38px;
  cursor: default;

  .logoWrapper {
    max-width: 32px;
    margin-right: 9px;
  }

  .logoTitle {
    font-size: 28px;
    line-height: 1;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
  }
}

.privatePageBehavior {
  cursor: pointer;
}
