@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.noBorders {
  border: none !important;

  li {
    border: none !important;
    padding: 0 !important;
  }

  .selectAllWrapper {
    border: none !important;
    padding: 0 !important;
  }
}

.contentWrapper {
  max-width: 225px;
  width: 100%;
  border: 1px solid rgba(151, 151, 151, 0.33);
  border-radius: 5px;

  .labelWrapper {
    background: $snowWhite;
    border-radius: 5px 5px 0 0;
    min-height: 30px;
    padding: 4px 0 4px 34px;

    @include flex(row, flex-start, center, nowrap);

    .label {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.777778px;
      color: $black;
    }
  }

  .whiteBg {
    background: $white !important;
    padding: 0 !important;
  }

  .selectAllWrapper {
    border-bottom: 1px solid $softWhite;
    min-height: 23px;
    padding: 0 0 0 11px;

    @include flex(row, flex-start, center, nowrap);
  }

  .checkboxList {
    @include ulReset();

    .checkboxListItem {
      min-height: 23px;
      padding: 0 0 0 11px;

      @include flex(row, flex-start, center, nowrap);

      &:not(:last-child) {
        border-bottom: 1px solid $softWhite;
      }

      &:last-child {
        border-bottom: 1px solid $white;
      }
    }
  }

  .labelRootClass {
    @include flex(row, flex-start, center, nowrap);

    margin: 0;
  }

  .labelTextClass {
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.9px;
    color: rgba(0, 0, 0, 0.96);
  }

  .checkboxRootClass {
    padding: 0 12px 0 0;

    svg {
      font-size: 12px;
    }
  }

  .checkedIconBox {
    width: 12px;
    height: 12px;
  }

  .checkedIconClass {
    min-height: 12px;
    min-width: 12px;

    div {
      min-height: 12px;
      min-width: 12px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .unCheckedIcon {
    width: 12px;
    height: 12px;
  }
}
