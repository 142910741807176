@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(column, flex-start, center, nowrap);

  .titleWrapper {
    @include flex(column, flex-start, center, nowrap);

    position: relative;
    margin-bottom: 65px;
  }

  .titleBlock {
    max-width: 530px;
    margin-bottom: 9px;
  }

  .title {
    margin-bottom: 21px;
  }

  .resendBtn {
    &:hover {
      text-decoration: underline;
    }
  }

  .resendBtn,
  .timer {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    text-transform: capitalize;
    color: $turtleGreen;
  }

  .resendDisabled {
    min-width: 172px;
    color: $grey;

    &:hover {
      text-decoration: none;
    }
  }

  .timer {
    min-width: 62px;

    @include flex(row, flex-start, center, nowrap);
  }

  .form {
    @include flex(column, flex-start, center, nowrap);

    max-width: 400px;
    width: 100%;
    margin-bottom: 8px;

    .fieldWrapper {
      margin-bottom: 44px;
    }
  }
}
