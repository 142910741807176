@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentHeadingBox {
  padding: 10px 30px;

  .title {
    font-size: 28px;
    line-height: 1;
    letter-spacing: -0.777778px;
    color: $black;
  }
}

.widgetsWrapper {
  @include flex(column, flex-start, flex-start, nowrap);
  row-gap: 16px;
  width: 100%;
}

.noReport {
  text-align: center;
  padding: 2em 0;
  font-size: 2em;
}

.loaderWrapper {
  text-align: center;
  padding: 3em 0;

  span {
    margin: -10px 0 0 -30px;
  }
}

.totalInvestmentBlock {
  margin: 16px 0;
}

.totalInvestmentBlock,
.maturityChartBlock,
.statusByVintageBlock {
  padding: 28px 24px;
}
