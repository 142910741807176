@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.toggleContainer {
  @include flex(row, flex-end, center, wrap);
  gap: 0.6em 1.2em;
  margin: 0 0.6em;

  button {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    padding: 0.25em 0.8em;

    &.active {
      background-color: $lightGrey;
    }
  }
}
