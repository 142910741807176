@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.panel {
  @include flex(row, flex-start, flex-end, nowrap);
  width: 100%;
  border-radius: 10px;
  background-color: $white;

  .tabsRoot {
    .tabLink {
      @include fontVerlag(22px, 400);
      padding: 20px 0;
      color: $black42;
      text-transform: capitalize;
      margin: 0 30px;
      letter-spacing: 0;
    }

    .activeTabLink {
      color: $black42;
    }

    .indicator {
      background-color: $black42;
    }
  }
}
