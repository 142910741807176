@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  @include flex(row, center, center, nowrap);

  .buttonBox {
    @include flex(row, center, center, nowrap);
    position: relative;
    max-width: 435px;
    width: 100%;
    transition: all $timeFast ease-in-out;
  }

  .buttonBoxActive {
    max-width: 345px;
  }

  .button {
    @include flex(row, center, center, nowrap);
    width: 100%;
    background-color: $turtleGreen;
    color: $white;
    padding: 0 2.5em;
    transition: all $timeFast ease-in-out;

    &:disabled {
      cursor: default;
      background-color: $doveGray;
      border: 2px solid $doveGray;
    }
  }
}
