@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.controlPanel {
  @include flex(row, space-between, center, nowrap);
  padding: 0 30px;

  .tabsWrapper {
    width: initial;
    margin-bottom: 0;

    .tabLinkClass {
      padding: 36px 0;
    }
  }

  .box {
    border: 1px solid red;
    width: 50px;
    height: 50px;
  }
}
