@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.fieldWrapper {
  position: relative;
  width: 100%;

  .label {
    display: block;
  }

  .input {
    @include fontRaleway(25px, 400);

    border: 0;
    border-bottom: 1px solid $softGrey;
    line-height: 29px;
    color: $titleBlack;
    width: 100%;
    padding: 0 0 6px 0;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      color: $softGrey;

      @include fontRaleway(25px, 400);
    }
  }

  .validPasswordMark {
    position: absolute;
    top: 50%;
    right: 3px;
    transform: translateY(-50%);
  }

  .errorMessage {
    position: absolute;
    bottom: -27px;
    left: 0;
    font-size: 15px;
    line-height: 18px;
    z-index: 20;
    transition: all $timeFast ease-in-out;
    color: $red;
  }

  .invalidInputData {
    border-bottom: 1px solid $darkRed;
    color: $red;

    &::placeholder {
      color: $red;
    }
  }
}
