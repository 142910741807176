@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.homeContent {
  @include flex(column, flex-start, center, nowrap);

  .logoBlock {
    @include flex(row, flex-start, center, nowrap);

    cursor: default;
    margin-bottom: 46px;

    .logoWrapper {
      max-width: 71px;
      margin-right: 17px;
    }

    .logoTitle {
      font-size: 60px;
      line-height: 72px;
      letter-spacing: -0.777778px;
      color: $turtleGreen;
    }
  }

  .title {
    text-align: center;
    color: $titleBlack;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 52px;
  }

  .btnBlock {
    max-width: 382px;
    width: 100%;

    @include flex(row, space-between, center, nowrap);
  }
}

.signInAsPreviousWrapper {
  @include flex(column, flex-start, center, nowrap);

  .signInAsPreviousBtn {
    @include fontRaleway(25px, 400);

    height: 55px;
    text-transform: unset;
    color: $black42;
    border: 1px solid $turtleGreen2;
    border-radius: 10px;
    margin-bottom: 14px;
  }

  .signInAsDifferentBtn {
    @include btnReset();

    text-align: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: $titleBlack;
    border-bottom: 1px solid transparent;
    transition: all $timeFast ease-in-out;

    &:hover {
      border-bottom: 1px solid rgba($titleBlack, 0.8);
    }
  }
}
