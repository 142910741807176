@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.input {
  background-color: $inputBackground;
  padding: 3px 10px;
  border: none;
  border-radius: 5px;
  color: $turtleGreen;
  box-shadow: none;
  transition: all $timeFast ease-in-out;
}

.error {
  box-shadow: 0 0 2px 1px rgba(255, 0, 0, 0.4);
  color: $red;
}
