@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.checkboxWrapper {
  @include flex(row, flex-start, center, nowrap);

  .checkboxRoot {
    padding: 0;
  }

  .checkedBox,
  .uncheckedBox {
    width: 12px;
    height: 12px;

    svg {
      width: 10px;
      height: 11px;
    }
  }
}

.allocationText {
  font-size: 15px;
  color: $black;
}

.allocationName {
  text-transform: none;
}

.statusText {
  color: $turtleGreen;
}

.statusRed {
  color: $lightRed;
}

.investingBlock {
  width: 100%;
  cursor: pointer;
  @include flex(row, center, center, nowrap);

  .investingBtn {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    max-width: 100px;
    width: 100%;
  }

  .investingText {
    font-weight: 700;
    font-size: 12px;
    text-transform: capitalize;
  }

  .startIcon,
  .stopIcon {
    min-width: 9px;
    margin-right: 10px;
  }

  .nonActive {
    color: $black42;
  }

  .active {
    color: $lightRed;
  }
}

.actions {
  @include flex(row, center, center, nowrap);

  .actionBtn {
    @include flex(row, center, center, nowrap);
    @include btnReset();

    position: relative;
    fill: transparent;
    stroke: $turtleGreen;
    padding: 6px;

    &:hover {
      .actionPopover {
        z-index: 10;
        transform: translate(-50%, -150%) scale(1);
        opacity: 1;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      &:hover .actionPopover {
        transform: translate(-50.5%, -150%) scale(1);
      }
    }
  }

  .actionPopover {
    @include flex(row, center, center, nowrap);
    background-color: $white;
    box-shadow: 0px 4px 10px 0px rgba($black, 25%);
    border-radius: 6px;
    padding: 6px 6px 4px;
    font-size: 15px;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: all $timeFast ease-in-out;

    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-color: $white;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: rotate(45deg) translateY(50%);
    }
  }

  .actionBtnDisabled {
    cursor: default;
    stroke: $disabledGrey;
  }
}
