@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.nameBlock {
  @include flex(row, flex-start, flex-start, nowrap);
  width: 100%;

  .allocationViewInfo {
    @include flex(row, flex-start, flex-start, nowrap);
    gap: 10px;

    .allocationViewLabel,
    .allocationViewValue {
      font-size: 22px !important;
      line-height: 26px !important;
      letter-spacing: -0.777778px;
    }

    .allocationViewValue {
      @include flex(row, flex-start, center, nowrap);
    }
  }

  .nameWrapperClass {
    @include flex(row, flex-start, center, nowrap);
    gap: 10px;
  }

  .inputClass {
    @include fontVerlag(22px, 400);

    line-height: 1;
    letter-spacing: -0.777778px;
    color: $black;
    padding: 2px 12px;
    background: $white;
    border: 1px solid rgba(151, 151, 151, 0.46);
    border-radius: 10px;
    min-width: 150px;
    max-width: 745px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.53);
      letter-spacing: -0.777778px;

      @include fontVerlag(22px, 400);
    }
  }

  .fieldLabelClass {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.777778px;
    color: $black;
  }

  .errorClass {
    bottom: 0;
    transform: translateY(115%);
  }
}
