@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.content {
  @include flex(column, flex-start, center, nowrap);
}

.passwordSuccessWrapper {
  @include flex(column, flex-start, center, nowrap);

  .titleBlock {
    text-align: center;
    color: $titleBlack;
    max-width: 510px;
    margin-bottom: 21px;

    .title {
      @include flex(row, center, center, nowrap);

      font-size: 40px;
      line-height: 48px;
      margin-bottom: 11px;

      &::before {
        content: '';
        display: inline-block;
        width: 30px;
        height: 22px;
        background: url('../../assets/images/icons/checkMark.svg') no-repeat center;
        background-size: contain;
        margin: 3px 18px 0 0;
      }
    }

    .description {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
