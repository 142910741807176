@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.datePicker {
  background: $datePickerWhite;
  border-radius: 5px;
  border: none;
  max-width: 107px;
  min-height: 29px;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  text-align: center;
  color: $black;
  transition: all $timeFast ease-in-out;

  @include flex(row, center, center, nowrap);

  &:hover {
    background: $datePickerBlack;
  }
}

.datePickerOpen {
  background: $datePickerBlack;
}

.popper {
  background: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 5px 5px;
  max-width: 248px;
  width: 100%;
  top: -13px;
  z-index: 11;
}

.calendar {
  max-width: 248px;
  width: 100%;
}

.weekDay {
  width: 36px;
  height: 30px;
  color: $stoneBlack;
  line-height: 30px;
  font-size: 14px;
  font-weight: 400;

  @include flex(row, center, center, nowrap);
}

.day {
  cursor: pointer;
  max-width: 36px;
  width: 100%;
  height: 30px;
  padding: 3px 0;

  @include flex(row, center, center, nowrap);

  &:focus {
    outline: none;
    border: none;
  }
}

.disabledDay {
  opacity: 0.7;
  background-color: gray;
  color: red;
}

.innerDay {
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: $cloudGrey;
  border: 1px solid rgba($turtleGreen, 0);

  @include flex(row, center, center, nowrap);

  &:hover {
    background-color: rgba(194, 188, 188, 0.1);
  }
}

.innerDaySelectedMonth {
  color: $stoneBlack;
}

.header {
  @include flex(column, flex-start, center, nowrap);

  .title {
    width: 100%;
    padding: 6px 0 4px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.9px;
    color: $black;
    border-bottom: 1px solid $softWhite;
    text-align: center;
  }

  .clearDateButton {
    @include btnReset();
    @include flex(row, center, center, nowrap);
    font-weight: 500;
    font-size: 15px;
    line-height: 13px;
    color: $black;
    padding: 8px;
    transition: all $timeFast ease-in-out;
    border-bottom: 1px solid $softWhite;
    width: 100%;

    &:hover {
      color: $turtleGreen;
    }
  }

  .dateNavigationBlock {
    @include flex(row, space-between, center, nowrap);

    width: 100%;
    max-height: 30px;
    padding: 5px 0 6px;
    border-bottom: 1px solid $softWhite;

    .dateNavButton {
      @include btnReset();

      font-size: 12px;
      line-height: 12px;
      color: $black;
      padding: 2px 10px;
    }

    .selectBlock {
      @include flex(row, center, center, nowrap);
    }

    .dropdownArrow {
      padding: 0 4px;
    }
  }
}
