@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  padding: 28px 24px;
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 400;
  border-bottom: 2px solid $softWhite;
  padding: 0 12px 12px;
  margin-bottom: 24px;
}

.loaderWrapper {
  @include flex(row, center, center, nowrap);

  width: 100%;
  height: 426px;
}

.body {
  @include flex(row, space-between, center, nowrap);
  padding-left: 12px;

  .summary {
    width: 400px;
    font-size: 18px;
    font-weight: 400;

    .rowsBlock {
      @include flex(column, center, initial, nowrap);

      &:nth-child(n + 2) {
        margin-top: 30px;
      }

      & > *:nth-child(n + 2) {
        margin-top: 10px;
      }

      .row,
      .subrow {
        @include flex(row, space-between, center, nowrap);
      }

      .subrow {
        margin-left: 30px;
      }
    }
  }

  .chart {
    @include flex(row, center, center, nowrap);

    flex-grow: 1;

    .pieChart {
      max-width: 370px;
    }

    .chartLegend {
      @include flex(column, flex-end, flex-start, nowrap);
      row-gap: 7px;
      margin-left: 30px;

      .legentRow {
        @include flex(row, flex-start, center, nowrap);
        cursor: pointer;
      }

      .legentDot {
        width: 11px;
        height: 11px;
        border-radius: 50%;
      }

      .legentDescription {
        margin-left: 12px;
      }
    }
  }
}
