@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.pageWrapper {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.publicContentWrapper {
  position: relative;
  width: 100%;
  height: calc(100% - 64px);

  .publicCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.privateContentWrapper {
  width: 100%;
  max-width: 100%;
  height: calc(100% - 64px);
  display: flex;
  overflow-y: auto;
  background: $silkGrey;

  .privateContent {
    width: 100%;
    max-width: 100%;
    height: 100%;

    .contentWrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }

  .innerPadding {
    padding: $innerPadding;
  }

  .privateInnerFull {
    max-height: calc(100% - 44px);
    height: 100%;
  }
}

.privateInner {
  border-radius: 10px;
  min-height: calc(100vh - 64px);
}

.contentPadding {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-color: $white;
  border-radius: 10px;
}
