@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentBox {
  @include flex(column, flex-start, flex-start, nowrap);
  position: relative;
  width: 100%;
  padding: 18px 24px;
  background: $silkGrey;
  border-radius: 10px;
  transition: all $timeFast ease-in-out;
}

.detailsRow {
  @include flex(row, flex-start, center, nowrap);
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  .detailsLabel {
    font-weight: 700;
    margin-right: 4px;
  }

  .detailsCurrencyValue {
    @include flex(row, flex-start, center, nowrap);
  }

  .capitalizeValue {
    text-transform: capitalize;
  }
}

.historyTitle {
  font-weight: 700;
  margin-bottom: 4px;
}

.historyDataBox {
  &:not(:last-of-type) {
    margin-bottom: 2px;
  }
}

.historyDataBox {
  &:not(:last-of-type) {
    margin-bottom: 2px;
  }
}
