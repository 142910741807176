@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.modalWrapper {
  padding: 3em;
  display: flex;
  flex-direction: column;
}

.clearFiltersBtn {
  @include btnReset();
  @include flex(row, center, center, nowrap);

  font-weight: 700;
  font-size: 15px;
  width: 20px;
  line-height: 14px;
  letter-spacing: -0.777778px;
  color: $turtleGreen;
  padding: 10px;
  transition: all $timeFast ease-in-out;
  margin-right: 24px;

  &:hover {
    text-decoration: underline;
  }
}

.modalHeader {
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 2em;
    margin-bottom: 1em;
  }
}

.modalTable {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.secretKey {
  max-width: 430px;
  display: inline-block;
  word-break: break-word;
}

.modalTableHeader {
  display: grid;
  grid-template-columns: 20% 80%;
  padding: 1em 0.5em;
  margin-top: 3em;

  border-top: 1px solid $softWhite;
  border-bottom: 1px solid $softWhite;
  padding: 0 16px 0 16px;
  background: #fff;
  box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.05);

  @media (min-width: 1920px) {
    padding: 0 20px 0 20px;
  }

  p {
    padding: 0.8em 0.5em;
  }

  .checkboxRoot {
    padding: 0;
    margin-right: 50px;
  }

  .checkedBox,
  .uncheckedBox {
    width: 12px;
    height: 12px;

    svg {
      width: 10px;
      height: 11px;
    }
  }

  p {
    text-align: left;
  }
}

.modalTableRow {
  display: grid;
  grid-template-columns: 20% 80%;
  background: rgba(7, 113, 134, 0.04);
  padding: 0 16px 0 16px;

  @media (min-width: 1920px) {
    padding: 0 20px 0 20px;
  }

  .checkboxRoot {
    padding: 0;
    margin-right: 50px;
  }

  .checkedBox,
  .uncheckedBox {
    width: 12px;
    height: 12px;

    svg {
      width: 10px;
      height: 11px;
    }
  }

  p {
    padding: 0.5em;
    display: flex;
    align-items: center;
  }

  .itemWithBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-left: 1em;
    }
  }
}
