@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.headerBlock {
  border-top: 1px solid $softWhite;
  border-bottom: 1px solid $softWhite;
  min-height: 48px;

  .checkboxRoot {
    padding: 0 0 3px;
  }

  .checkedBox,
  .uncheckedBox {
    width: 12px;
    height: 12px;

    svg {
      width: 10px;
      height: 11px;
    }
  }

  .tHeadCell {
    position: relative;
    padding: 11px 6px;
    white-space: nowrap;
  }

  .tHeadTopColumnCell {
    @include fontVerlag(22px, 400);
    border-bottom: none;
    color: $black;
  }

  .listItemSortable {
    cursor: pointer;
  }

  .tableCellContent {
    @include flex(row, flex-start, center, nowrap);
  }

  .label {
    font-weight: 700;
    font-size: 15px;
    text-transform: capitalize;
    color: $black;
  }

  .labelSortable {
    cursor: pointer;
    margin-right: 5px;
  }

  .sortArrowWrapper {
    @include flex(row, center, center, nowrap);

    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .dropdownContent {
    position: absolute;
    top: 47px;
    background: $white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 5px 5px;
    z-index: 11;

    .optionsList {
      @include ulReset();
    }

    .optionListItem {
      @include flex(row, center, center, nowrap);

      cursor: pointer;
      position: relative;
      text-align: center;
      padding: 5px 18px;
      min-height: 36px;
      transition: all $timeFast ease-in-out;

      &:not(:last-child) {
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100%;
          height: 1px;
          background-color: $white;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
        }
      }

      &:hover {
        background-color: rgba($turtleGreen, 0.14);

        &::after {
          background-color: rgba(7, 113, 134, 0.03);
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.07);
        }
      }
    }

    .optionLabel {
      font-size: 18px;
      text-transform: capitalize;
      color: $black;
      white-space: nowrap;
    }
  }

  .dropdownContentLeft {
    left: 0;
  }
  .dropdownContentRight {
    right: 0;
  }
  .dropdownContentCenter {
    left: 50%;
    transform: translateX(-50%);
  }
}
