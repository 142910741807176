@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: 100%;
  height: calc(100% - 64px);
  padding: 85px 89px 65px 65px;

  @include flex(row, center, center, nowrap);

  .contentWrapper {
    max-width: 760px;
    width: 100%;
    margin-right: 44px;
  }

  .imageWrapper {
    max-width: 482px;
  }
}
