@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.reqErrorText {
  position: static;
  transform: none;
  left: 0;
  font-size: 22px;
  line-height: 1.3;
  margin-bottom: 20px;

  &::before {
    display: none;
  }
}
