@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentHeadingBox,
.contentHeadingFilters {
  @include flex(row, space-between, center, nowrap);
}

.contentHeadingBox {
  .title {
    font-size: 28px;
    line-height: 1;
    letter-spacing: -0.777778px;
    color: $black;
  }
}

.contentHeadingFilters {
  .datePickerLabel {
    margin-right: 12px;
  }

  .pickerGroup {
    @include flex(row, flex-start, center, nowrap);
    gap: 12px;
  }

  .filtersBox {
    @include flex(row, flex-end, center, nowrap);
  }

  .fileTypeWrapper {
    margin-right: 16px;
  }

  .clearFiltersBtn {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
    padding: 10px;
    margin-right: 16px;
    transition: all $timeFast ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }

  .filterContainer {
    width: 300px;
    height: auto;
  }

  .filterBtn {
    padding: 0 25px;
    min-width: 113px;
  }
}

.noReport {
  text-align: center;
  padding: 2em 0;
  font-size: 2em;
}

.loaderWrapper {
  text-align: center;
  padding: 3em 0;

  span {
    margin: -10px 0 0 -30px;
  }
}
