@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  @include flex(row, flex-start, center, nowrap);

  .currencySelectWrapper {
    margin-right: 18px;
  }

  .nationalityBlock {
    @include flex(row, center, center, nowrap);

    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 18px;
  }

  .currencyBlock {
    cursor: default;
    margin-right: 18px;

    .currency {
      font-size: 18px;
      line-height: 32px;
      color: $black42;
    }
  }

  .notificationsBlock {
    margin-right: 18px;
  }

  .avatarBlock {
    margin-right: 18px;
  }

  .profileTitleBlock {
    min-width: 116px;
    height: 40px;
    background-color: $lightGrey;
    border-radius: 10px;
    margin-right: 18px;
    padding: 0 27px;

    @include flex(row, center, center, nowrap);

    .profileTitle {
      font-size: 22px;
      line-height: 32px;
      color: $black42;
      white-space: nowrap;
    }
  }
}
