@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.deleteGroupInfo {
  min-width: 400px;
  padding: 16px 31px;
  background: $white;
  border-radius: 10px;
  transition: all $timeFast ease-in-out;
}

.deleteInfoTextBlock {
  margin-bottom: 20px;
}

.deletePopupTitle,
.deletePopupText {
  line-height: 25px;
  color: $black;
}

.deletePopupTitle {
  font-size: 15px;
}

.deletePopupText {
  font-size: 12px;
}

.deletePopupBtnBlock {
  @include flex(row, flex-end, center, nowrap);
}

.deletePopupBtn {
  @include btnReset();
  @include flex(row, center, center, nowrap);

  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  padding: 8.5px 40px;
  color: $disabledGrey;
  transition: all $timeFast ease-in-out;

  &:hover {
    color: $black42;
  }
}

.cancelBtn {
  border-right: 1px solid $softWhite;
  padding: 8.5px 39px 8.5px 40px;
}
