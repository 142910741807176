@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.rangeBlock {
  @include flex(row, space-between, center, nowrap);

  margin-bottom: 25px;

  .rangeFilterBox {
    max-width: 325px;
    width: 100%;

    @include flex(column, flex-start, center, nowrap);

    @media (min-width: 1920px) {
      max-width: 380px;
    }
  }
}
