@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: 100%;

  .content {
    width: 100%;
  }

  .tableContainer {
    min-height: unset;
    max-height: 55vh;
  }

  .tableContainerSticky {
    overflow-y: auto;
    overflow-x: hidden;
    background-color: transparent;

    &::-webkit-scrollbar {
      width: 5px;
      border: none;
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: $scrollTrackWhite;
      border-left: 1px solid $silkGrey;
      border-right: 1px solid $silkGrey;
      border-radius: 50px;
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollThumbGrey;
      border-radius: 50px;
      width: 6px;
    }
  }

  .tHeadClass {
    border-top: none;

    .tHeadCellClass {
      height: 36px;
      padding: 9.75px 6px;
      background-color: $silkGrey;
    }

    .tHeadCellClassSticky {
      position: sticky;
      top: -1px;
    }

    .tHeadLabelClass {
      font-size: 13px;
      line-height: 1.2;
    }

    .tHeadTopColumnCellClass {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: -0.777778px;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: transparent;

      &:last-of-type {
        padding-left: 6px;
      }
    }
  }

  .tBodyRowClass {
    &:nth-of-type(even) {
      background-color: rgba(7, 113, 134, 0.1);
    }
  }

  .tBodyCellClass {
    padding: 7px 6px;
    height: 36px;
  }

  .tableCellText {
    font-size: 13px;
    line-height: 1.2;
  }
}
