@import './src/assets/styles/mixins.scss';
@import './src/assets/styles/variables.scss';

.contentHeadingBox {
  margin-bottom: 0;

  .contentHead {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
  }
}

.opportunities {
  position: absolute;
  z-index: -1;
  margin-top: 16px;
  max-width: 100%;

  .checkboxRoot {
    padding: 0 0 3px;
  }

  .fileTypeSelectWrapper {
    margin-right: 16px;
  }

  &_control {
    margin-bottom: 1.5rem;
    max-width: 100%;

    &_selectedNumber {
      font-weight: 700;
      margin-right: 2rem;
      white-space: nowrap;
    }

    &_invest {
      margin-right: 2rem;
    }

    &_export {
      @include flex(row, space-between, center, nowrap);
      margin: 0 1rem;
    }

    &_right,
    &_left {
      @include flex(row, space-between, center, nowrap);
    }
  }

  &_tableList {
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    &_sme {
      grid-template-columns: repeat(7, 1fr);
    }
  }
}

.opportunitiesShown {
  position: static;
  z-index: 0;
}

.opportunitiesWithLoadMore {
  padding: 30px 30px 0;
}

.modal {
  &_content {
    position: relative;
    min-width: 533px;
    min-height: 250px;
    padding: 2rem;
    @include flex(column, center, center, nowrap);
  }
}

.investComplete {
  @include flex(column, center, center, wrap);
  text-align: center;

  .title {
    max-width: 371px;
    margin-bottom: 36px;
  }

  .titleWithFailed {
    margin-bottom: 12px;

    span {
      display: block;
    }
  }

  .text {
    font-size: 15px;
    margin-bottom: 36px;
  }

  .button {
    min-width: 155px;
    max-width: 155px;
  }
}

.complete {
  @include flex(column, center, center, wrap);
  text-align: center;

  &_bottom {
    @include flex(row, space-between, center, wrap);
  }

  &_title {
    margin-bottom: 1rem;
  }

  &_text {
    margin-bottom: 2rem;
  }

  &_button {
    margin: 0 1rem;
  }

  .listItemClass[data-checked='false'] {
    display: none;
  }

  .textCenter {
    text-align: center;
  }
}

.dateAndFilterWrapper {
  @include flex(row, space-between, center, nowrap);

  .clearFiltersBtn {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
    padding: 10px;
    transition: all $timeFast ease-in-out;
    margin-right: 24px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.noOpportunities {
  text-align: center;
  padding: 2em 0;
  font-size: 2em;
}

.loaderWrapper {
  text-align: center;
  padding: 3em 0;
}

.uncheckedBox {
  border: 1px solid $uncheckedCheckboxGrey;
}

.checkedBox,
.uncheckedBox {
  width: 12px;
  height: 12px;

  svg {
    width: 10px;
    height: 11px;
  }
}

.textInfo {
  font-size: 15px;
  text-transform: capitalize;
  color: $black;
  white-space: nowrap;
}

.relativeValue {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: $turtleGreen;
}

.flagWrapper {
  @include flex(row, flex-start, center, nowrap);

  img {
    width: 19px;
  }

  .platformText {
    margin-left: 10px;
  }
}

.scoreA {
  color: $woodenGreyLight;
}

.scoreB {
  color: $woodenGrey;
}

.scoreC {
  color: $yellowSand;
}

.scoreD {
  color: $corall;
}

.scoreE {
  color: $darkRed;
}

.scoreF {
  color: $disabledGrey;
}

.status1 {
  color: $currentInvestment;
}

.status2 {
  color: $completedInvestment;
}

.status3 {
  color: $lateInvestment;
}
