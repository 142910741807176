@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.portfolioProfitability {
  display: flex;
  background-color: $white;
  flex-direction: column;
  padding: 28px 24px;
  width: 100%;

  .portfolioStructureHeader {
    @include flex(row, space-between, center, nowrap);
    padding: 0 12px 12px;
    border-bottom: 1px solid $lightGrey;

    h2 {
      font-size: 1.5em;
    }
  }

  .tableContainer {
    min-height: unset;
  }
}

.scoreA {
  color: $woodenGreyLight;
}

.scoreB {
  color: $woodenGrey;
}

.scoreC {
  color: $yellowSand;
}

.scoreD {
  color: $corall;
}

.scoreE {
  color: $darkRed;
}

.scoreF {
  color: $disabledGrey;
}
