@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  gap: 20px;
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);

  @media screen and (min-width: 1440px) {
    grid-template-columns: 45% 55%;
  }

  @media screen and (min-width: 1920px) {
    grid-template-columns: 40% 60%;
  }

  .inputBlock {
    @include flex(row, flex-start, center, nowrap);
    gap: 5px;

    .label {
      width: 100%;
      font-size: 15px;
      line-height: 32px;
      text-transform: capitalize;
    }

    .input {
      @include inputReset();
      @include flex(row, center, center, nowrap);
      width: 100%;
      max-width: 90px;
      height: 23px;
      background: $inputBackground;
      border-radius: 5px;
      color: $turtleGreen;
      padding: 0 10px;
      text-align: center;
      transition: all $timeFast ease-in-out;
    }

    .inputReadOnly {
      cursor: default;
    }
  }

  .sliderBox {
    @include flex(row, flex-start, center, nowrap);
    width: 100%;
    max-width: 100%;

    .sliderReadOnly {
      cursor: default;
      color: $turtleGreen;
    }
  }
}
