$white: #fff;
$black: #000;
$inputBackground: #f3f3f3;
$curiousBlue: #20a5ce;
$pictonBlue: #46cce1;
$mercury: #e8e8e8;
$elephant: #0f3249;
$doveGray: #666;
$lightGrey: #e4e5ea;
$alabaster: #f7f7f7;
$cerulean: #00a7cf;
$gallery: #eaeaea;
$softWhite: #e5e5e5;
$snowWhite: #f4f4f4;
$silkGrey: #f4f3f3;
$turtleGreen: #077186;
$turtleGreen2: #177d84;
$green: $turtleGreen;
$green2: $turtleGreen2;
$green3: #69b6be;
$green4: #3a7c83;
$black42: #0f1942;
$titleBlack: #111a3f;
$stoneBlack: #424141;
$datePickerWhite: #f3f3f3;
$datePickerBlack: #07718626;
$red: #f00;
$darkRed: #d80027;
$lightRed: #e0391c;
$softGrey: #9dafbd;
$disabledGrey: #979797;
$disabledText: #8d8d8d;
$grey: #c4c4c4;
$cloudGrey: #c2bcbc;
$scrollTrackWhite: #f2f3f6;
$scrollThumbGrey: #d5d5d5;
$uncheckedCheckboxGrey: #c8c3c3;
$yellowSand: #e4ba89;
$corall: #f47853;
$woodenGrey: #8a7c5a;
$woodenGreyLight: #8a9c5a;
$map: #ececec;
$mapHover: #4fa5b0;

$timeBond: 0.07s;
$timeFast: 0.15s;
$timeMedium: 0.3s;

$innerPadding: 22px;

$currentInvestment: #077186;
$lateInvestment: #f47853;
$completedInvestment: #0f1942;
$defaultedInvestment: #d80027;
$cancelledInvestment: #979797;

$chartColors: #f47853, #e4ba89, #9c9c9c, #8a7c5a, #8a9c5a, #74a093, #589ba4, #4fa5b0, #177d84;

$pieCharColors: #24454c, #077186, #1093a7, #589ba4, #4fa5b0, #8bc8cf, #b0dade, #def2f3;
$lightGreen: rgb(0, 175, 0);
