@import './src/assets/styles/mixins.scss';

.allocationSelector {
  @include flex(row, flex-start, center, nowrap);

  .title {
    font-size: 22px;
    margin-right: 18px;
  }
}
