@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  @include flex(column, flex-start, flex-start, nowrap);
  gap: 12px;
}

.criteriaGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  width: 100%;
}

.criteriaColumn {
  @include flex(column, flex-start, flex-start, nowrap);
  gap: 12px;
  width: 100%;
}
