@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.title {
  @include fontVerlag(22px, 400);
  line-height: 26px;
  letter-spacing: -0.777778px;
  color: rgba($black, 0.97);
}

.lg {
  @include fontVerlag(22px, 400);
}

.md {
  @include fontVerlag(18px, 400);
  line-height: 22px;
}

.sm {
  @include fontVerlag(16px, 400);
  line-height: 22px;
}
