@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: 100%;
}

.portfolioAllocationBox {
  @include flex(column, flex-start, flex-end, nowrap);

  .exportBlock {
    @include flex(row, flex-end, center, nowrap);
    margin-top: 8px;
  }

  .refreshWrapper {
    margin-top: 0;

    .lastUpdatedClass {
      margin-right: 22px;
    }
  }
}

.filterGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2em;
}

.dateAndFilterWrapper {
  @include flex(row, flex-start, center, nowrap);

  .clearFiltersBtn {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
    padding: 10px;
    transition: all $timeFast ease-in-out;
    margin-right: 24px;

    &:hover {
      text-decoration: underline;
    }
  }

  .filterBtn {
    min-width: 113px;
    border: 2px solid $black42;
    border-radius: 10px;
    padding: 0 38px;
  }
}
