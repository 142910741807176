@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(row, flex-start, flex-end, nowrap);

  margin-bottom: 42px;

  .fieldLabelClass {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.777778px;
    color: $black;
    margin-bottom: 8px;
  }

  .allocationName {
    font-size: 1em !important;
    display: inline-block;
    overflow-wrap: break-word;
    max-width: 30vw;
  }

  .allocationNameLabel {
    font-size: 1em !important;
  }

  .allocationInfoBlock {
    display: flex;
    margin-right: 3em;
    align-items: flex-start;

    > p {
      display: flex;
    }

    span,
    p {
      font-size: 1.5em;
    }
  }

  .inputClass {
    @include fontVerlag(22px, 400);

    line-height: 1;
    letter-spacing: -0.777778px;
    color: $black;
    padding: 2px 12px 2px 25px;
    background: $white;
    border: 1px solid rgba(151, 151, 151, 0.46);
    border-radius: 10px;
    min-width: 150px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.53);
      letter-spacing: -0.777778px;

      @include fontVerlag(22px, 400);
    }
  }

  .nameWrapperClass {
    max-width: 384px;
    margin-right: 31px;
  }

  .errorClass {
    bottom: 0;
    transform: translateY(115%);
  }

  .amountBlock {
    @include flex(column, flex-start, flex-start, nowrap);

    .amountInner {
      @include flex(row, flex-start, flex-end, nowrap);
    }

    .currencySelectWrapper {
      margin-right: 14px;
    }

    .currencySelectValue {
      background: #fff;
      border: 1px solid rgba(151, 151, 151, 0.46);
      font-size: 20px;
      border-radius: 10px;
      padding: 2px 20px;
    }
  }

  .amountWrapperClass {
    max-width: 226px;
  }

  .viewText {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.777778px;
    color: $black;
    padding: 0 8px 3px 8px;
    border-bottom: 1px solid $black;

    span {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: -0.777778px;
      color: $black;
    }
  }

  .viewLabel {
    margin-bottom: 18px;
  }

  .viewNameWrapper {
    @include flex(column, flex-start, flex-start, nowrap);

    margin-right: 30px;
  }

  .viewName {
    min-width: 370px;
  }

  .viewCurrency {
    margin-right: 15px;
    min-width: 78px;
    padding: 0 12px 3px 12px;
    text-align: center;
  }

  .viewAmount {
    min-width: 220px;
    text-align: center;
  }
}
