@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.detailsBlock {
  @include flex(row, space-between, center, nowrap);
  padding: 0 2em 0 0;
  margin-bottom: 33px;
}

.detailsText {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.777778px;
  color: $black;
  padding: 0 !important;
  min-width: 120px !important;
}

.detailsValueBox {
  border-radius: 5px;
  background-color: $inputBackground;
  padding: 0 20px;
  height: 33px;

  @include flex(row, center, center, nowrap);
}

.investmentBlock {
  @include flex(row, flex-start, center, nowrap);

  .investmentLabel {
    margin-right: 4px;
  }
}

.investmentInput {
  @include inputReset();
  text-align: center;
  background-color: $inputBackground;
  color: $turtleGreen;
  max-width: 62px;
  box-shadow: none;
  transition: all $timeFast ease-in-out;
}

.investmentInputError {
  box-shadow: 0 0 2px 1px rgba(255, 0, 0, 0.4);
  color: $red;
}
