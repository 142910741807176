@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: 100%;

  .tableContainer {
    min-height: 500px;
    box-shadow: none;
    border-radius: 0;
    color: $black;
  }
}

.noPaginationWrapper {
  padding-bottom: 1em;
}
