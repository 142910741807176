@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  @include flex(row, flex-start, center, nowrap);
}

.center {
  @include flex(row, flex-start, center, nowrap);
}

.start {
  @include flex(row, flex-start, flex-start, nowrap);
}

.end {
  @include flex(row, flex-start, flex-end, nowrap);
}

.value,
.after {
  letter-spacing: -0.777778px;
  color: $black;
}

.value {
  @include fontVerlag(16px, 400);
  line-height: 18px;
  margin-right: 6px;
}

.after {
  @include fontVerlag(13px, 400);
  line-height: 17px;
}
