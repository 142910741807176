@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  max-width: 380px;

  .titleWrapper {
    @include flex(row, flex-start, center, nowrap);

    margin-bottom: 28px;
    position: relative;

    .title {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: -0.777778px;
      color: $black;
      margin-right: 14px;
    }

    .infoIcon {
      width: 13px;
      height: 13px;
      cursor: pointer;

      @include flex(row, center, center, nowrap);

      div {
        width: 13px;
        height: 13px;

        @include flex(row, center, center, nowrap);
      }
    }

    .infoText {
      position: absolute;
      top: 2px;
      right: -100%;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.777778px;
      color: $turtleGreen;
      transition: all $timeFast ease-in-out;

      span {
        display: block;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.777778px;
        color: $turtleGreen;
      }
    }
  }

  .radioGroup {
    @include flex(row, flex-start, center, nowrap);

    margin-left: -8px;

    .radioLabelRoot {
      margin-right: 0;

      &:not(:last-of-type) {
        margin-right: 32px;
      }
    }
  }
}
