@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.arrow {
  max-width: 18px;
  max-height: 11px;

  @include flex(row, center, center, nowrap);

  svg {
    max-width: 18px;
    margin-bottom: -1px;
  }
}
