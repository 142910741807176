@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  .title {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.777778px;
    color: $black;
    margin-bottom: 40px;
  }

  .radioGroupRoot {
    @include flex(row, flex-start, center, nowrap);

    margin-left: -8px;

    .labelRootClass {
      margin-right: 0;

      &:not(:last-of-type) {
        margin-right: 105px;
      }
    }
  }
}
