@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  min-height: 500px;

  .title {
    font-size: 38px;
    line-height: 46px;
    color: $black;
    margin-bottom: 125px;

    span {
      font-size: 50px;
      line-height: 60px;
      font-weight: 700;
      color: $black42;
    }
  }

  .description {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 40px;

    span {
      color: $turtleGreen;
      font-size: 28px;
      line-height: 34px;
    }
  }

  .button {
    margin-left: 228px;
  }
}
