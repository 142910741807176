@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  width: 100%;
  height: 100%;

  .notFound {
    font-size: inherit;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .titleWrapper {
    @include flex(row, space-between, center, nowrap);
    width: 100%;

    .title {
      font-size: 28px;
      line-height: 1;
      letter-spacing: -0.777778px;
      color: $black;
    }

    .newAllocationBtn {
      font-size: 15px;
      line-height: 1;
      padding: 0 45px;
    }

    .newAllocationPlusIcon {
      margin-right: 8px;
    }
  }

  .tableWrapper {
    padding: 28px 24px;
    background-color: $white;
    border-radius: 10px;
  }

  .tContainerClass {
    min-height: 450px;
  }

  .nameSortDropdown {
    left: 40px;
  }
}
