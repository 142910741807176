@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentBodyBox {
  padding: 30px 30px 0 30px;
}

.originatorBox {
  @include flex(row, flex-start, center, nowrap);
}

.textInfo {
  font-size: 15px;
  text-transform: capitalize;
  color: $black;
  white-space: nowrap;
}

.flagWrapper {
  @include flex(row, center, center, nowrap);
  margin-right: 10px;

  img {
    width: 20px;
    min-width: 20px;
  }
}

.relativeValue {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
  color: $turtleGreen;
}

.scoreAA {
  color: $turtleGreen;
}

.scoreA {
  color: $woodenGreyLight;
}

.scoreB {
  color: $woodenGrey;
}

.scoreC {
  color: $yellowSand;
}

.scoreD {
  color: $corall;
}

.scoreE {
  color: $darkRed;
}

.scoreF {
  color: $disabledGrey;
}
