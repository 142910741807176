@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: 100%;
  border-radius: 12px;

  .titleClass,
  .investmentLabelClass,
  .rangeLabel {
    font-size: 18px;
  }

  .investmentAfterClass,
  .loanAmountAfter,
  .interestAfter {
    font-size: 13px;
  }

  .termAfter {
    font-size: 15px;
  }

  .rangeAfter {
    margin-left: 6px;
  }
}

.rangeBlock {
  @include flex(row, space-between, center, nowrap);
  margin-bottom: 10px;

  .rangeFilterBox {
    @include flex(column, flex-start, center, nowrap);
    max-width: 265px;
    width: 100%;

    @media (min-width: 1920px) {
      max-width: 380px;
    }
  }
}
