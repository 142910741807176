@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.criteriasWrapper {
  @include flex(column, center, center, nowrap);
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 3px);
  z-index: 9999;
  border-radius: 12px;
  padding: 1.3em;
  box-shadow: 0px 4px 10px 0px #00000040;

  .criteriasCheckBoxWrapper {
    width: 13em;
  }

  .activeTypeButton {
    background-color: $elephant;
    color: $white;
    border-color: $elephant;
    margin-top: 15px;
    width: 100%;

    &:hover {
      background-color: $elephant;
      color: $white;
    }
  }

  .checkboxCursorDefault {
    cursor: default;

    span {
      cursor: default;
    }
  }
}
