@import './src/assets/styles/mixins.scss';

.typography {
  letter-spacing: -0.777778px;

  &_display {
    &_initial {
      display: initial;
    }

    &_block {
      display: block;
    }

    &_inline {
      display: inline;
    }
  }

  &_variant {
    &_inherit {
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    &_subtitle1 {
      font-size: 1.75rem;
    }

    &_subtitle2 {
      font-size: 1.25rem;
      line-height: 1.5;
    }

    &_subtitle3 {
      font-size: 1rem;
      line-height: 1.4;
    }

    &_caption {
      font-size: 0.625rem;
      line-height: 1.3;
    }

    &_body1 {
      font-size: 1.125rem;
      line-height: 1.5;
    }

    &_body2 {
      font-size: 1rem;
      line-height: 1.5;
    }

    &_body3 {
      font-size: 0.875rem;
      line-height: 1.5;
    }

    &_body4 {
      font-size: 12px;
      line-height: 1.5;
    }

    &_h1 {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1.4;
    }

    &_h2 {
      font-size: 2.125rem;
      font-weight: 600;
      line-height: 1.4;
    }

    &_h3 {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.4;
    }

    &_h4 {
      font-size: 1.125rem;
      line-height: 1.4;
    }

    &_h5 {
      font-size: 1rem;
      line-height: 1.4;
    }

    &_h6 {
      font-size: 0.875rem;
      line-height: 1.4;
    }
  }

  &_color {
    &_textSecondary {
      color: var(--textSecondary);
    }

    &_textPrimary {
      color: $black;
    }

    &_textWhite {
      color: $white;
    }

    &_textThird {
      color: var(--textThird);
    }

    &_textLight {
      color: var(--textLight);
    }

    &_textDark {
      color: var(--textDark);
    }

    &_secondary {
      color: var(--secondary);
    }

    &_primary {
      color: var(--primary);
    }

    &_third {
      color: var(--third);
    }

    &_initial {
      color: initial;
    }

    &_inherit {
      color: inherit;
    }

    &_valid {
      color: var(--valid);
    }

    &_error {
      color: var(--error);
    }
  }

  &_align {
    &_left {
      text-align: left;
    }

    &_center {
      text-align: center;
    }

    &_right {
      text-align: right;
    }

    &_justify {
      text-align: justify;
    }
  }

  &_weight {
    &_300 {
      font-weight: 300;
    }
    &_400 {
      font-weight: 400;
    }
    &_500 {
      font-weight: 500;
    }
    &_600 {
      font-weight: 600;
    }
    &_700 {
      font-weight: 700;
    }
  }

  &_uppercase {
    text-transform: uppercase;
  }

  &_nowrap {
    display: block;
    width: 100%;
    max-width: 100%;
  }
}
