@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.baseWrapper {
  position: relative;
}

.wrapper {
  position: absolute;
  background-color: $white;
  border-radius: 0 0 5px 5px;
  box-shadow: 2px 2px 15px -2px #00000054;
  padding: 15px;
  color: $black;
  min-width: 150px;
  z-index: 999999;
  top: 25px;
  right: 0;

  &:not(.active) {
    z-index: -100;
  }

  .header {
    @include flex(row, center, center, nowrap);

    font-size: 15px;

    .totalPercent {
      color: $turtleGreen;
      margin-left: 5px;
    }
  }

  .body {
    @include flex(row, flex-start, flex-start, nowrap);

    margin-top: 15px;
    cursor: initial;

    .column {
      @include flex(column, flex-start, flex-start, nowrap);

      &:nth-child(n + 2) {
        margin-left: 20px;
      }
    }
  }

  .columnItem {
    @include flex(row, space-between, center, nowrap);

    width: 100%;
    white-space: nowrap;

    .input {
      width: 80px;
      margin-left: 9px;
      text-align: center;
    }

    &:nth-child(n + 2) {
      margin-top: 10px;
    }
  }

  &.standalone {
    position: initial;
    transform: none;
    box-shadow: none;

    .dotLegendWrapper {
      @include flex(row, flex-start, center, nowrap);

      flex-grow: 1;
      cursor: pointer;
    }

    .legendMarker {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin: 5px;
      min-width: 11px;
    }
  }
}

.dotLegendTitle {
  cursor: pointer;
}
