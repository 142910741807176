@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.mapOuterWrapper {
  position: relative;
  display: flex;
  flex: 1;

  svg:focus {
    outline: none;
  }
}

.svgMapWrapper {
  @include flex(row, center, stretch, nowrap);
  flex: 1;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  cursor: grab;

  &::-webkit-scrollbar {
    display: none;
  }
}

.svgMapWrapperScrolling {
  overflow: auto;
  cursor: grabbing;
}

.svgMapWrapperDefault {
  cursor: default;
}

.svgMapWrapperPointer {
  cursor: pointer;
}

.mapOuterWrapper .tooltip {
  display: inline-block;
  background: $white;
  white-space: nowrap;
  width: fit-content;
  border: 1px solid $turtleGreen;
  border-radius: 30px 0 30px 30px;
  padding: 0.75rem 3rem;
  transition: all $timeFast ease;

  &::before,
  &::after {
    display: none;
  }
}

.mapOuterWrapper .zoomButtons {
  @include flex(column, flex-start, center, nowrap);
  position: absolute;
  bottom: 4px;
  right: 5px;
  box-shadow: 0 1px 4px rgb($black, 0.25);
  border-radius: 8px;
  background-color: $white;
  transition: all $timeFast ease-in-out;
  z-index: 11;

  &:hover {
    box-shadow: 0 1px 4px rgba($turtleGreen, 0.25);
  }

  .zoomButton {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    cursor: pointer;
    width: 22px;
    height: 22px;
    transition: all $timeFast ease-in-out;
    font-size: 18px;

    &:hover {
      .zoomIcon {
        fill: $turtleGreen;
      }
    }
  }

  .zoomButtonDisabled {
    cursor: default;

    .zoomIcon {
      fill: $map;
    }

    &:hover {
      .zoomIcon {
        fill: $map;
      }
    }
  }

  .zoomIcon {
    width: 18px;
    height: 18px;
    fill: $black42;
    transition: all $timeFast ease-in-out;
  }

  .zoomDivider {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    height: 1px;
    background-color: $map;
    cursor: pointer;
  }
}
