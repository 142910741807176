.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.ul-reset {
  @include ulReset();
}

.nav-circle {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $lightGrey;

  @include flex(row, center, center, nowrap);
}
