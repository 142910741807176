@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.box {
  width: 15px;
  height: 15px;
  background: $white;
  border: 1px solid $softGrey;
  transition: all $timeFast ease-in-out;
}

.boxChecked {
  width: 15px;
  height: 15px;
  background: $white;
  border: 1px solid rgba($turtleGreen, 0.7);

  @include flex(row, center, center, nowrap);
}

.checkMarkIcon {
  max-height: 15px;
  max-width: 15px;

  @include flex(row, center, center, nowrap);

  div {
    max-height: 15px;
    max-width: 15px;

    @include flex(row, center, center, nowrap);
  }

  svg {
    width: 13px;
    height: 13px;
  }
}
