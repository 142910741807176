@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  @include flex(row, flex-start, flex-start, nowrap);

  margin-bottom: 16px;
  width: 100%;

  .labelWrapper {
    @include flex(row, flex-start, center, wrap);

    .label {
      cursor: pointer;
      color: $titleBlack;
      margin-bottom: 0;
    }

    .label,
    .link {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
    }

    .link {
      @include btnReset();
      @include flex(row, center, center, nowrap);

      display: inline;
      color: $turtleGreen;
    }
  }
}
