@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  width: 100%;
  padding: 25px 90px 30px 30px;
}

.fieldsBlock {
  width: 100%;
  display: grid;
  grid-template-columns: 384px 323px 226px;
  justify-content: space-between;

  &:first-of-type {
    margin-bottom: 25px;
  }
}

.amountBlock,
.typeBlock,
.autoInvestBlock {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;

  .allocationViewInfo {
    @include flex(column, flex-start, flex-start, nowrap);

    .allocationViewLabel,
    .allocationViewValue {
      font-size: 22px !important;
      line-height: 26px !important;
      letter-spacing: -0.777778px;
    }

    .allocationViewAmount,
    .allocationViewCurrency,
    .allocationViewValue {
      @include flex(row, flex-start, center, nowrap);
    }

    .allocationViewAmount {
      column-gap: 14px;
    }

    .allocationViewCurrency,
    .allocationViewValue {
      color: $turtleGreen;
    }

    .allocationViewCurrency {
      margin-bottom: -1px;
    }

    .allocationViewLabel {
      margin-bottom: 10px;
    }
  }

  .allocationViewCurrency {
    font-size: 22px !important;
    line-height: 26px !important;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
  }

  .inputClass {
    @include fontVerlag(22px, 400);

    line-height: 1;
    letter-spacing: -0.777778px;
    color: $black;
    padding: 2px 12px;
    background: $white;
    border: 1px solid rgba(151, 151, 151, 0.46);
    border-radius: 10px;
    min-width: 150px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.53);
      letter-spacing: -0.777778px;

      @include fontVerlag(22px, 400);
    }
  }

  .fieldLabelClass {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.777778px;
    color: $black;
    margin-bottom: 8px;
  }

  .errorClass {
    bottom: 0;
    transform: translateY(115%);
  }

  .checkboxRootClass {
    padding: 0 14px 0 0;

    svg {
      font-size: 12px;
    }
  }

  .checkedIconBox {
    width: 12px;
    height: 12px;
  }

  .checkedIconClass {
    min-height: 12px;
    min-width: 12px;

    div {
      min-height: 12px;
      min-width: 12px;
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .unCheckedIcon {
    width: 12px;
    height: 12px;
  }

  .labelRootClass {
    width: fit-content;
  }

  .labelRootViewClass,
  .checkboxRootViewClass {
    cursor: default;
  }

  .labelTextClass {
    color: $black;
    font-size: 16px;
  }
}

.amountBlock {
  max-width: 323px;

  .amountInner {
    @include flex(row, flex-start, center, nowrap);
    column-gap: 14px;
  }

  .maxBidSizeInner {
    @include flex(row, flex-start, center, nowrap);
    column-gap: 14px;
    width: 100%;

    .maxBidGreySizeCurrency {
      @include flex(row, center, center, nowrap);
      color: rgba($black, 0.53);
    }
  }
}

.typeBlock {
  max-width: 226px;

  .datePicker {
    width: 100%;
  }

  .dateInput {
    @include fontVerlag(20px, 400);
    line-height: 24px;
    letter-spacing: -0.77px;
    border: 1px solid rgba(151, 151, 151, 0.46);
    background: $white;
    width: 100%;
    max-width: 100%;
    min-height: 32px;
    border-radius: 10px;

    ::placeholder {
      @include fontVerlag(20px, 400);
      line-height: 24px;
      letter-spacing: -0.77px;
    }
  }

  .disabledDay {
    pointer-events: none;
    span {
      color: $cloudGrey;
    }
  }
}
