@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.settingsHeaderWrapper {
  margin-bottom: 16px;

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.5em;
    margin: 1.7em 0 0.7em 0;
  }
}

.settingsFormWrapper {
  display: flex;
}

.contentWrapper {
  @include flex(row, flex-start, flex-end, nowrap);
  margin-bottom: 42px;

  .fieldLabelClass {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.777778px;
    color: $black;
    margin-bottom: 8px;
  }

  .inputClass {
    @include fontVerlag(22px, 400);

    line-height: 1;
    letter-spacing: -0.777778px;
    color: $black;
    border-bottom: 0;
    padding: 4.5px 12px 2px 25px;
    background: $white;
    border: 1px solid rgba(151, 151, 151, 0.46);
    border-radius: 10px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.53);
      letter-spacing: -0.777778px;

      @include fontVerlag(22px, 400);
    }
  }

  .errorClass {
    bottom: 0;
    transform: translateY(115%);
  }

  .amountWrapperClass {
    max-width: 350px;
  }
}

.activeTypeButton {
  background-color: $turtleGreen;
  color: $white;
  margin-left: 1em;

  &:hover {
    background-color: $turtleGreen;
    color: $white;
  }
}

.error {
  color: red;
}

.deleteBtn {
  width: 100%;
}

.tableContainer {
  min-height: unset !important;
}
