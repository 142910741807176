@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.circularLoader {
  animation-name: spin;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
