@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.titleBlock {
  @include flex(column, flex-start, center, nowrap);

  text-align: center;
  margin-bottom: 27px;

  .title {
    font-size: 40px;
    line-height: 48px;
    color: $titleBlack;
    margin-bottom: 13px;
  }

  .hideMargin {
    margin-bottom: 0;
  }

  .description {
    font-size: 20px;
    line-height: 24px;
    color: $titleBlack;
  }

  .link {
    font-size: 20px;
    line-height: 24px;
    color: $turtleGreen;

    &:hover {
      text-decoration: underline;
    }
  }
}

.extraMargin {
  margin-bottom: 40px;
}
