@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.rangeBarContainer {
  display: flex;

  p {
    @include flex(row, center, center, nowrap);
  }
}

.detailsValueBox {
  border-radius: 5px;
  background-color: $inputBackground;
  color: $turtleGreen;
  padding: 0 10px;
  height: 28px;
  width: 100px;
  border: none;
  text-align: center;
  box-shadow: none;
  transition: all $timeFast ease-in-out;

  @include flex(row, center, center, nowrap);
}

.error {
  box-shadow: 0 0 2px 1px rgba(255, 0, 0, 0.4);
  color: $red;
}
