@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.refreshAllocationBox {
  @include flex(row, flex-end, center, nowrap);
  margin-top: 13px;

  .lastUpdateDate {
    font-size: 15px;
    line-height: 1;
    letter-spacing: -0.777778px;
    color: $disabledGrey;
    margin-right: 16px;
  }

  .refreshBtnBox {
    @include flex(row, flex-start, center, nowrap);
    min-width: 220px;
  }

  .refreshButton {
    font-size: 15px;
    line-height: 1;
    letter-spacing: -0.777778px;
    color: $green4;
    text-transform: unset;
  }

  .refreshLoaderBox {
    @include flex(row, center, center, nowrap);
    min-width: 50px;
  }
}

.refreshBoxReverse {
  @include flex(row-reverse, flex-end, center, nowrap);

  .refreshBtnBox {
    @include flex(row, flex-end, center, nowrap);
    margin-right: 16px;
  }
}
