@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.typesWrapper {
  @include flex(row, space-between, center, nowrap);
  width: 100%;

  .typesBlock {
    display: flex;
    align-items: center;

    .label {
      font-size: 22px;
      margin-right: 16px;
    }

    button {
      font-size: 15px;
      line-height: 1;
      padding: 0 12px;
      min-width: 210px;
    }
  }

  .typesButtons {
    display: flex;
    column-gap: 16px;
  }

  .newAllocationLink {
    @include flex(row, center, center, nowrap);

    width: 210px;
    height: 35px;
    padding: 0 12px;
    background: $turtleGreen;
    color: $white;
    border-radius: 10px;
    font-size: 15px;
    line-height: 1;
    font-weight: 700;
    text-decoration: none;
    text-transform: capitalize;
  }
}

.activeTypeButton {
  background-color: $turtleGreen;
  color: $white;

  &:hover {
    background-color: $turtleGreen;
    color: $white;
  }
}
