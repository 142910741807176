@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  min-height: 720px;

  .title {
    font-size: 38px;
    line-height: 46px;
    color: $black;
    margin-bottom: 60px;

    span {
      font-size: 50px;
      line-height: 60px;
      font-weight: 700;
      color: $black42;
    }
  }

  .description,
  .text,
  span {
    font-size: 28px;
    line-height: 34px;
    color: $black;
  }

  .description {
    margin-bottom: 40px;

    span {
      display: block;
      margin-top: 10px;
    }
  }

  .list {
    @include ulReset();

    margin-bottom: 90px;

    .listItem {
      &:not(:last-child) {
        margin-bottom: 40px;
      }
    }

    .text {
      span {
        display: block;
      }
    }
  }
}
