@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  @include flex(row, space-between, center, nowrap);
  width: 100%;
  padding: 20px 30px;

  .title {
    @include fontVerlag(28px, 300);
    line-height: 34px;
    letter-spacing: -0.777778px;
    color: $black;
  }

  .btnBlock {
    @include flex(row, flex-end, center, nowrap);
    gap: 30px;

    .btnBack {
      color: $black42;
      font-size: 16px;
    }

    .btnConfirm {
      background-color: $black42;
      max-width: 165px;

      &:disabled {
        background-color: $doveGray;
      }
    }

    .btnView {
      background-color: $turtleGreen;
    }
  }
}
