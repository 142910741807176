@import './src/assets/styles/variables.scss';

.rollingReturnHeader {
  display: flex;
  justify-content: space-between;
  padding: 0 12px 12px;
  border-bottom: 2px solid $lightGrey;

  h2 {
    font-size: 24px;
  }

  .calendarWrapper {
    display: flex;
    align-items: center;
    span {
      margin-right: 0.5em;
    }
  }
}

.headerToggleWrapper {
  text-align: right;
  padding: 1em 0;
}
