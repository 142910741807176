@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  margin-right: 24px;

  .formControlRoot {
    margin-right: 0;
  }

  .label {
    @include fontVerlag(15px, 400);
    color: $darkRed;
    line-height: 24px;
    letter-spacing: -0.9px;
    transition: all $timeFast ease-in-out;
  }

  .labelChecked {
    color: $black42;
  }

  .checkboxRoot {
    padding: 4px 8px;
  }

  .iconRoot,
  .iconRootChecked {
    width: 16px;
    height: 16px;
    fill: $darkRed;
    transition: all $timeFast ease-in-out;
  }

  .iconRootChecked {
    fill: $black42;
  }
}
