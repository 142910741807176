@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.profileDropdownBlock {
  position: relative;

  .profileDropdownIcon {
    margin-top: 3px;
  }
}

.dropdown {
  position: absolute;
  top: 53px;
  right: 0;
  min-width: 241px;
  background: $white;
  border-radius: 2px;
  opacity: 1;
  transition: all $timeFast ease-in-out;

  @include flex(column, flex-start, flex-start, nowrap);

  .avatarBlock {
    @include flex(row, flex-start, center, nowrap);

    cursor: default;
    width: 100%;
    padding: 18px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    .avatarWrapper {
      cursor: default;
      width: 54px;
      height: 54px;

      img {
        width: 27.5px;
      }
    }

    .profileName {
      font-size: 22px;
      line-height: 32px;
      color: $black42;
      white-space: nowrap;
    }

    .myProfileLink {
      cursor: default;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.777778px;
      color: $disabledGrey;
    }
  }

  .menuItem {
    cursor: pointer;
    width: 100%;
    min-height: 71px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all $timeFast ease-in-out;
    padding: 0 30px;

    @include flex(row, center, center, nowrap);

    &:hover {
      background-color: rgba(7, 113, 134, 0.14);
    }
  }

  .disableMenuItem {
    cursor: default;

    &:hover {
      background-color: initial;
    }

    span {
      color: #86878c;
    }

    .icon path {
      fill: #86878c;
    }
  }

  .menuLinkActive {
    background-color: rgba(7, 113, 134, 0.14);
  }

  .menuLinkContent {
    @include flex(row, center, center, nowrap);
  }

  .iconWrapper {
    @include flex(row, center, center, nowrap);

    max-width: 21px;
    margin-right: 28px;

    .icon {
      max-width: 17px;
    }
  }

  .linkTitle {
    min-width: 123px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.777778px;
    color: $black42;
  }
}

.enter {
  opacity: 0;
  display: block;
}

.enterActive {
  opacity: 1;
  transition: opacity 200ms;
}

.enterDone {
  opacity: 1;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition: opacity 200ms;
}

.exitDone {
  display: none;
}
