@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.btn {
  @include btnReset();
  @include flex(row, center, center, nowrap);

  padding: 0 58px;
  min-width: 160px;
  border-radius: 10px;
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  text-transform: capitalize;
  color: $turtleGreen;
  white-space: nowrap;
  transition: all $timeFast ease-in-out;

  &:disabled {
    cursor: default;
    background-color: $grey;
    pointer-events: none;
    border: 2px solid $grey;

    &:hover {
      background-color: gray;
    }
  }

  &_transparent {
    border: 2px solid $turtleGreen;

    &:hover {
      background: rgba(48, 112, 133, 0.07);
    }
  }

  &_blue {
    background-color: $turtleGreen;
    color: $white;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.9;
    }
  }

  &_red {
    background-color: $lightRed;
    color: $white;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.9;
    }
  }

  &_height {
    &_xs {
      min-height: 35px;
    }
    &_sm {
      min-height: 40px;
    }
  }
}
