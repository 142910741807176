@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.header {
  position: relative;
  width: 100%;
  height: 64px;
  filter: drop-shadow(0 2px 10px rgba(0, 0, 0, 0.25));
  padding: 12px 26px;
  background-color: $white;
  z-index: 12111;
}

.headerPrivateContent {
  @include flex(row, space-between, center, nowrap);

  width: 100%;
}
