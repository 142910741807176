@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.selectContentWrapper {
  @include flex(row, flex-start, center, nowrap);

  .label {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.777778px;
    color: $black;
    margin-right: 12px;
  }
}
