@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.countBox {
  @include flex(row, center, center, nowrap);
  min-width: 24px;
  min-height: 24px;
  padding: 4px;
  border-radius: 50%;
  background-color: $lightRed;
  color: $white;
  font-size: 1rem;
  line-height: 1;
  margin-left: -2px;
  transition: all $timeFast ease-in-out;
}

.countBoxBig {
  min-width: 26px;
  min-height: 26px;
  max-width: 26px;
  max-height: 26px;
}

.noOpportunities {
  background-color: $disabledGrey;
}
