@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.btn {
  @include flex(row, center, center, nowrap);
  @include btnReset();
  @include fontVerlag(20px, 700);

  line-height: 36px;
  color: $turtleGreen;
  text-transform: capitalize;

  &:hover {
    text-decoration: underline;
  }
}

.btnDisabled {
  color: $disabledGrey;
  cursor: default;

  &:hover {
    text-decoration: none;
  }
}
