@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.privateContentClass {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollTrackWhite;
    border-left: 1px solid $silkGrey;
    border-right: 1px solid $silkGrey;
    border-radius: 50px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbGrey;
    border-radius: 50px;
    width: 6px;
  }

  .contentPaddingClass {
    padding: 0;

    @media (max-width: 1439px) {
      padding: 0;
    }
  }

  .privateInnerClass {
    background-color: transparent;
  }
}

.content {
  padding-bottom: 40px;
}

.confirmBlock,
.typeBlock,
.platformsBlock,
.criteriaManage {
  margin-bottom: 12px;
}
