@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.platformBox {
  @include flex(row, space-between, center, nowrap);
  cursor: pointer;
  padding: 0 24px;
  width: 262px;
  height: 60px;
  background: rgba($green, 0.05);
  border: 1px solid rgba($green3, 0.5);
  border-radius: 5px;
  transition: all $timeFast ease-in-out;
  filter: grayscale(0) opacity(1) contrast(1);
}

.platformBoxSelected {
  background: rgba($green, 0.25);
  border: 1px solid $green;

  &:hover {
    background: rgba($green, 0.25);
    border: 1px solid $green;
  }
}

.logoBox {
  @include flex(row, center, center, nowrap);
  min-width: 111px;
  max-width: 111px;
  min-height: 28px;
  max-height: 28px;

  > img {
    max-height: 111px;
    max-height: 28px;
  }
}

.platformTitle {
  @include fontVerlag(15px, 400);
  line-height: 2.4;
  text-transform: capitalize;
  color: $green4;
}

.managePlatformContent {
  min-width: 665px;
  background: $white;
  border-radius: 10px;
  padding: 26px 40px 34px;

  .manageTitleBlock {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
  }

  .allocationAmountBox {
    @include flex(row, flex-start, center, nowrap);
    gap: 20px;
  }

  .platformsList {
    @include flex(column, flex-start, flex-start, nowrap);
    gap: 12px;
    padding: 22px 0 25px;
    border-bottom: 2px dashed rgba($black, 0.2);
    margin-bottom: 22px;
  }

  .manageSelectedPlatform {
    background: rgba($green, 0.05);
    border: 1px solid rgba($green3, 0.5);

    &:hover {
      background: rgba($green, 0.05);
      border: 1px solid rgba($green3, 0.5);
    }
  }

  .totalRow {
    display: grid;
    grid-template-columns: 265px 1fr 1fr;
    align-items: center;
    width: 100%;
    padding-right: 5px;
    margin-bottom: 47px;

    .totalPercentageBox,
    .totalAmountBox,
    .total {
      @include flex(row, flex-end, center, nowrap);
    }

    .total {
      padding-right: 5px;
    }

    .totalPercentageBox,
    .totalAmountBox {
      gap: 8px;
    }

    .totalPercentage,
    .totalAmount {
      @include flex(row, center, center, nowrap);
      min-width: 103px;
      margin-right: 0;
    }

    .totalAmount {
      margin-top: -1px;
    }
  }

  .confirmButton {
    min-width: 220px;
    background-color: $green;
    margin: 0 auto;
    transition: all $timeFast ease-in-out;
  }

  .confirmButtonDisabled {
    background-color: $disabledGrey;
  }

  .platformRow {
    display: grid;
    grid-template-columns: 265px 1fr 1fr;
    align-items: center;
    width: 100%;
    padding-right: 5px;

    .platformEmpty {
      filter: grayscale(1) opacity(0.95) contrast(0.8);
      border-color: transparent;
    }

    .platformCursorDefault {
      cursor: default;
    }

    .inputBox {
      @include flex(row, flex-end, center, nowrap);
      gap: 8px;
    }

    .after {
      @include fontVerlag(13px, 400);
      line-height: 16px;
      letter-spacing: -0.777778px;
      color: $black;
    }

    .detailsValueBox {
      border-radius: 5px;
      background-color: $inputBackground;
      color: $turtleGreen;
      padding: 0 10px;
      height: 28px;
      min-width: 103px;
      max-width: 103px;
      border: none;
      text-align: center;
      box-shadow: none;
      transition: all $timeFast ease-in-out;

      @include flex(row, center, center, nowrap);
    }

    .detailsValueEmpty {
      color: $disabledText;
    }

    .error {
      box-shadow: 0 0 2px 1px rgba(255, 0, 0, 0.4);
      color: $red;
    }
  }
}
