@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  @include flex(row, flex-start, center, nowrap);
  width: 100%;
  background-color: $white;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 16px;
  height: 100px;
}
