@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;
  row-gap: 16px;
}

.statisticWidgetContainer {
  background-color: $white;
  width: 100%;
  padding: 28px 24px;
  border-radius: 12px;

  h2 {
    text-align: center;
    font-size: 24px;
    border-bottom: 2px solid $inputBackground;
    padding-bottom: 12px;
  }

  .statisticWidgetSlots {
    display: flex;
    justify-content: space-around;
    padding: 1.5em 0;
  }

  .statisticWidgetSlot {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slotText {
    font-size: 2em;
  }
}
