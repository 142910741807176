@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.title {
  @include fontVerlag(22px, 400);
  color: $black;
  letter-spacing: -0.778px;
}

.reqErrorText {
  position: static;
  transform: none;
  left: 0;
  font-size: 22px;
  line-height: 1.3;

  &::before {
    display: none;
  }
}

.form {
  @include flex(column, flex-start, flex-start, nowrap);
  width: '100%';

  .dateInputClass {
    max-width: 122px;
    height: 30px;
  }

  .inputWrapperClass {
    width: fit-content;
  }

  .inputClass {
    @include fontVerlag(15px, 400);
    width: 122px;
    height: 30px;
    border-radius: 5px;
    background: #f3f3f3;
    border: 1px solid $inputBackground;
    padding-bottom: 0;
    color: $turtleGreen;
    text-align: center;
    letter-spacing: -0.778px;
    transition: all 0.3s ease-in-out;

    &::placeholder {
      color: rgba(0, 0, 0, 0.53);
      letter-spacing: -0.777778px;

      @include fontVerlag(15px, 400);
    }
  }

  .inputError {
    border: 1px solid $darkRed;
  }

  .currencyLabel {
    @include fontVerlag(15px, 400);
    color: $black;
    line-height: normal;
    letter-spacing: -0.778px;
  }

  .submitBtn {
    width: 222px;
    height: 34px;
    border-radius: 5px;
    background: $turtleGreen;
    @include fontVerlag(15px, 400);
    color: $white;
    line-height: 36px;
    text-transform: capitalize;
    align-self: center;
  }
}
