@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.box {
  width: 100%;
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 14px;
}
