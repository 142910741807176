@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(row, space-between, center, nowrap);

  position: relative;
  width: 100%;
  min-height: 32px;
}

.selectBlock {
  @include flex(row, flex-start, center, nowrap);

  .selectLabel {
    font-size: 15px;
    line-height: 18px;
    color: $black;
  }
}
