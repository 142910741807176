@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.loaderWrapper {
  @include flex(row, center, center, nowrap);

  width: 100%;
  padding: 1em 0;
  height: 100%;

  .message {
    font-size: 2em;
  }

  .errorMsg {
    color: $lightRed;
  }
}
