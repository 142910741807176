@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  width: 710px;
  background: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 32px 22px;

  @include flex(column, flex-start, center, nowrap);
}

.detailsBlockWrapper {
  width: 100%;
  height: 306px;
  max-height: 306px;
  box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 36px;
}

.detailsBlock {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 10px;

  .detailsText {
    color: $titleBlack;
  }

  &::-webkit-scrollbar {
    width: 5px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-radius: 50px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbGrey;
    border-radius: 50px;
    width: 5px;
  }
}

.btnBlock {
  @include flex(row, space-between, center, nowrap);

  width: 260px;

  .btn {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    min-width: 80px;
    height: 35px;
    line-height: 1;
    font-weight: 700;
    font-size: 15px;
    color: $disabledGrey;
    border-radius: 10px;
    border: 1.5px solid transparent;
    transition: all $timeFast ease-in-out;

    &:hover {
      border: 1.5px solid $turtleGreen;
      color: $turtleGreen;
    }
  }
}
