@use 'sass:list';

@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .readonlyPercentage {
    pointer-events: none;
    cursor: default;
  }

  .percentage {
    margin-left: 2em;
  }

  .titleBlock {
    @include flex(column, center, flex-start, nowrap);

    font-size: 18px;
    width: 100%;
    line-height: 22px;
    letter-spacing: -0.777778px;
    color: rgba(0, 0, 0, 0.97);
    margin-bottom: 25px;
  }

  .hintWrapper {
    @include flex(row, flex-start, center, nowrap);

    position: relative;
    margin: 0.5em 0 1.5em 0;
  }

  .hintIcon {
    cursor: pointer;
    margin-left: 7px;

    &:hover + div {
      opacity: 1;
    }
  }

  .hintBlock {
    cursor: default;
    transition: all $timeFast ease-in-out;
    opacity: 0;
    font-size: 12px;
    color: $turtleGreen;
    margin: 0 0 -1px 10px;
  }

  .shownHint {
    opacity: 1;
  }

  .pieChart {
    margin-bottom: 20px;
  }

  .listWrapper {
    @include flex(column, flex-start, flex-start, nowrap);
    row-gap: 7px;
    width: 100%;
    max-width: 350px;
  }

  .listItem {
    @include flex(row, flex-start, center, nowrap);
    width: 100%;

    .checkboxRoot {
      padding: 0;
      margin-right: 9px;
    }

    .uncheckedBox {
      border: 1px solid $uncheckedCheckboxGrey;
    }

    .checkedBox,
    .uncheckedBox {
      width: 12px;
      height: 12px;

      svg {
        width: 10px;
        height: 11px;
      }
    }

    .styleDot {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      margin: 5px;
    }

    .listItemTitle {
      font-weight: 700;
      font-size: 15px;
      color: $black;
      white-space: nowrap;
      margin-right: 7px;
      cursor: default;
    }

    .inputWrapper {
      @include flex(row, flex-end, flex-start, nowrap);

      flex-grow: 1;
    }

    .input {
      text-align: center;
      max-width: 80px;
    }
  }

  .dropdown {
    @include flex(row, flex-start, center, nowrap);

    cursor: pointer;
    margin-right: 10px;
  }
}
