@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.btn {
  @include btnReset();
  @include flex(row, center, center, nowrap);

  min-width: 160px;
  height: 35px;
  background-color: $black42;
  border-radius: 10px;
  color: $white;
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
  transition: all $timeFast ease-in-out;
}

.btnDisabled {
  cursor: default;
  background-color: $disabledGrey;
}
