@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(column, flex-start, center, nowrap);

  .titleBlockWrapper {
    position: relative;
    margin-bottom: 130px;
  }

  .titleBlock {
    max-width: 400px;
  }

  .title {
    margin-bottom: 21px;
  }

  .form {
    @include flex(column, flex-start, center, nowrap);

    max-width: 400px;
    width: 100%;
    margin-bottom: 10px;

    .emailFieldWrapper {
      margin-bottom: 156px;
    }
  }
}
