@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.portfolioStructureWrapper {
  display: flex;
  background-color: $white;
  flex-direction: column;
  border-radius: 12px;
  padding: 28px 24px;
  width: 100%;

  .portfolioStructureHeader {
    display: flex;
    justify-content: space-between;
    padding: 0 12px 12px 12px;
    border-bottom: 2px solid $lightGrey;

    h2 {
      font-size: 1.5em;
    }
  }

  .toggleWrapper {
    @include flex(row, flex-start, center, wrap);
    margin: 1em 0.6em 2em 0.6em;
  }

  .toggleItem {
    font-size: 1.3em;
    padding: 0.25em 1em;
  }

  .perncentageWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row !important;
    align-items: center;

    > div:first-child {
      display: none !important;
    }
  }

  .calendarWrapper {
    display: flex;
    align-items: center;
    span {
      margin-right: 0.5em;
    }
  }

  .pieWrapper {
    margin-right: 3em;
    max-width: 370px;
    min-width: 250px;
    height: 45vh;
  }
}
