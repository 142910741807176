@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.dropdownArrow {
  max-width: 20px;
  cursor: pointer;

  @include flex(row, center, center, nowrap);

  div {
    @include flex(row, center, center, nowrap);
  }

  path {
    stroke: $softGrey;
    transition: all $timeFast ease-in-out;
  }

  &:hover {
    path {
      stroke: $turtleGreen;
    }
  }
}

.dropdownError {
  path {
    stroke: $red;
    transition: all $timeFast ease-in-out;
  }

  &:hover {
    path {
      stroke: $darkRed;
    }
  }
}

.selectWrapper {
  position: relative;
  width: 100%;

  .errorMessage {
    position: absolute;
    bottom: 20px;
    left: 0;
    font-size: 15px;
    line-height: 18px;
    z-index: 20;
    transition: all $timeFast ease-in-out;
    color: $red;
  }
}
