@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.errorMessage {
  position: absolute;
  bottom: -36px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  line-height: 24px;
  color: $red;
  white-space: nowrap;

  @include flex(row, flex-start, center, nowrap);

  &::before {
    content: '';
    width: 17px;
    height: 17px;
    display: inline-block;
    background: url('../../../assets/images/icons/request_error_x-mark.svg') no-repeat center;
    background-size: 17px;
    margin-right: 3px;
  }
}
