@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(column, flex-start, center, nowrap);

  .confirmInfoField {
    margin-bottom: 44px;

    &:last-of-type {
      margin-bottom: 46px;
    }
  }

  .confirmInfoSelectInput {
    margin-bottom: 44px;
  }

  .signUpForm {
    position: relative;
    max-width: 400px;
    margin-bottom: 4px;
    width: 100%;

    @include flex(column, flex-start, center, nowrap);

    .link {
      position: relative;
      display: block;
      font-size: 20px;
      line-height: 24px;
      color: $turtleGreen;
      cursor: pointer;
    }

    .agreementRoot {
      margin-bottom: 16px;
    }

    .offersRoot {
      margin-bottom: 45px;
      margin-left: 0;
      margin-right: 0;
    }

    .requestErrorMsg {
      bottom: -60px;
    }

    .submitBtn {
      margin-bottom: 10px;
    }
  }
}

.additionalPadding {
  padding: 108px 0;
}

.thanksContent {
  margin-top: -60px;
}

.thanksTitleBlock {
  max-width: 516px;
  margin-bottom: 22px;

  .thanksTitle {
    margin-bottom: 12px;
  }
}

.titleBlock {
  margin-bottom: 45px;
}
