@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.filterWrapper {
  position: relative;
}

.filterDropdown {
  position: absolute;
  top: 47px;
  right: 0;
  z-index: 15;
  width: 321px;
  height: 65vh;
  background-color: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 5px 5px;
  padding: 40px 16px 40px 44px;
}

.dropdownContent {
  padding: 0 34px 0 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    border: none;
  }

  &::-webkit-scrollbar-track {
    background-color: $scrollTrackWhite;
    border-left: 1px solid $white;
    border-right: 1px solid $white;
    border-radius: 50px;
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollThumbGrey;
    border-radius: 50px;
    width: 6px;
  }

  .checkboxBlocsList {
    @include ulReset();

    .checkboxBlockItem {
      &:not(:last-child) {
        margin-bottom: 17px;
      }
    }
  }
}
