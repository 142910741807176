@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.sliderBlockWrapper {
  width: 100%;
}

.hasError {
  border: 1px solid $defaultedInvestment !important;
  color: $defaultedInvestment !important;
  background-color: $white !important;
}

.sliderWrapper {
  width: 100%;
  padding: 0 10px;
}

.label {
  margin-left: 15px;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.777778px;
  color: $black;
}

.minMaxWrapper {
  width: 100%;

  @include flex(row, space-between, center, nowrap);

  .valueLabel {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
    text-align: center;
  }

  .valueLabelInput {
    @include inputReset();
    @include fontVerlag(13px, 400);

    max-width: 65px;
    line-height: 1;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
    border-bottom: 0;
    background: $white;
    background-color: $inputBackground;
    padding: 3px 10px;
    border: none;
    border-radius: 5px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.53);
      letter-spacing: -0.777778px;

      @include fontVerlag(13px, 400);
    }
  }

  .valueLabelInputWider {
    max-width: 6rem;
  }
}

.popperHidden {
  display: none;
}
