@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.loadMoreButton {
  @include btnReset();

  font-family: 'Verlag', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  display: block;
  text-align: center;
  color: $turtleGreen;
  cursor: pointer;
  width: 100%;
  padding: 1.3rem 1.1rem 1.6rem 1.1rem;
  margin: 0 auto;
}

.ftrLoaderBox {
  padding: 0;
}
