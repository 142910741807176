@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(row, flex-start, center, nowrap);
}

.exportAllBtn {
  min-width: 113px;
  border: 2px solid $black42;
  border-radius: 10px;
  padding: 0 22px;
  margin-right: 11px;
}

.confirmContent {
  position: relative;
  min-width: 472px;
  min-height: 210px;

  @include flex(column, center, center, nowrap);

  .fileIconWrapper {
    margin-bottom: 20px;
  }

  .confirmDownloadText {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.777778px;
    color: $black;
  }

  .confirmText {
    margin-bottom: 20px;
  }

  .btnBlock {
    max-width: 316px;
    width: 100%;

    @include flex(row, space-between, center, nowrap);

    .confirmBtn {
      min-width: 78px;
      padding: 0;
    }

    .cancelBtn {
      @include btnReset();
      @include flex(row, center, center, nowrap);

      max-width: 78px;
      width: 100%;
      height: 35px;
      font-weight: 700;
      font-size: 15px;
      line-height: 1;
      color: $disabledGrey;
      border: 2px solid $white;
      border-radius: 10px;
      transition: all $timeMedium ease-in-out;

      &:hover {
        border: 2px solid $black42;
        color: $black42;
        background: rgba(15, 25, 66, 0.07);
      }
    }
  }
}

.completeWrapper {
  @include flex(column, flex-start, center, nowrap);

  .completeTitleBlock {
    @include flex(row, center, center, nowrap);

    margin-bottom: 16px;

    .checkMark {
      width: 22px;
      height: 22px;
      margin-right: 14px;

      @include flex(row, center, center, nowrap);

      img {
        width: 22px;
      }
    }

    .completeTitle {
      font-size: 22px;
      line-height: 26px;
      color: $titleBlack;
    }
  }

  .completeFiletypeWrapper {
    max-width: 25px;
  }

  .closeBtn {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    position: absolute;
    width: 30px;
    height: 30px;
    top: 8px;
    right: 7px;
  }
}

.downloadingContent {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 210px;
  border-radius: 10px;

  @include flex(row, center, center, nowrap);

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 10px;
  }

  .downloadingFileIcon {
    width: 32px;
  }

  .loaderWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include flex(row, center, center, nowrap);
  }
}
