@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day-names {
  @include flex(row, space-between, center, nowrap);

  padding: 8px 12px 0;
}

.react-datepicker__month {
  padding: 0 12px 8px;
}

.react-datepicker__week {
  @include flex(row, space-between, center, nowrap);
}

.react-datepicker__day--today {
  span {
    font-weight: 700;
    color: $stoneBlack;
  }
}

.react-datepicker__day--selected {
  span {
    color: $turtleGreen;
    font-weight: 700;
    border: 1px solid rgba($turtleGreen, 0.7);
    border-radius: 4px;
  }
}
