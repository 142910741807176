@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.menuList {
  @include ulReset();

  width: 100%;
  text-align: center;

  .menuItem {
    cursor: pointer;
    width: 100%;
    height: 71px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .menuItemDisabled {
    position: relative;

    &::before {
      content: '';
      cursor: default;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .menuLink {
      background-color: rgba($disabledGrey, 0.2);
    }
  }

  .menuLink {
    @include flex(row, flex-start, center, nowrap);

    width: 100%;
    height: 100%;
    padding: 0 32px;
    transition: all $timeFast, ease-in-out;

    &:hover {
      background-color: rgba(7, 113, 134, 0.14);
    }
  }

  .menuLinkActive {
    background-color: rgba(7, 113, 134, 0.14);
  }

  .menuLinkContent {
    position: relative;
    width: 100%;

    @include flex(row, flex-start, center, nowrap);
  }

  .icon {
    min-width: 20px;
    max-width: 20px;
  }

  .linkTitle {
    position: absolute;
    left: 28px;
    min-width: 128px;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.777778px;
    color: $black42;
    transition: all $timeFast ease-in-out;
    opacity: 1;
  }

  .linkTitleHidden {
    opacity: 0;
  }
}

.popoverRoot {
  margin-left: 2px;

  .popoverPaper {
    box-shadow: 0px 2px 6px 0 rgb(0 0 0 / 25%);
    padding: 6px 12px;
  }
}
