@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.deleteMessageBlock {
  @include flex(column, flex-end, center, nowrap);
  width: 508px;
  height: 244px;
  background: $white;
  border-radius: 10px;
  padding: 40px 33px;

  .imgWrapper {
    width: 64px;
    margin-bottom: 15px;
  }

  .deleteMessageText {
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.777778px;
    color: $black;
    margin-bottom: 23px;
  }

  .deleteMessageBtn {
    min-width: 78px;
    padding: 0 14px;
  }
}
