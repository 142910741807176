@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.wrapper {
  padding: 20px 30px 25px;
  min-height: 151px;
  height: 151px;
  transition: all $timeFast ease-in-out;
}

.wrapperClean {
  min-height: 66px;
  height: 66px;
  padding: 20px 30px;
}

.titleBlock {
  @include flex(row, flex-start, flex-end, nowrap);
  gap: 20px;
  margin-bottom: 20px;
}

.titleBlockSingle {
  margin-bottom: 0;
}

.manageButton {
  @include btnReset();
  @include flex(row, center, center, nowrap);
  @include fontVerlag(15px, 700);
  line-height: 18px;
  padding: 0 10px;
  text-decoration-line: underline;
  color: $green;
}

.platformsBlock {
  @include flex(row, flex-start, flex-start, wrap);
  gap: 20px;
}
