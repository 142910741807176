@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.bnt {
  @include flex(row, center, center, nowrap);
  @include btnReset();

  cursor: pointer;
  background-color: $turtleGreen;
  border-radius: 10px;
  height: 35px;
  padding: 0 60px;
  color: $white;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  transition: all $timeFast ease-in-out;
}

.btnDisabled {
  cursor: default;
  background-color: $grey;
}

.beforeImg {
  width: 11px;
  height: 11px;
  margin-right: 12px;
}
