@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.datePickerBlock {
  @include flex(row, flex-start, center, nowrap);

  .dateLabel {
    margin-right: 20px;
  }

  .disabledDay {
    opacity: 0.7;
    pointer-events: none;
    span {
      color: red;
    }
  }

  .dateDivider {
    margin: 0 6px;
  }

  .dateText {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.9px;
    color: $black;
  }
}
