@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.filtersWrapper {
  margin-bottom: 18px;

  .titleWrapper {
    @include flex(row, space-between, center, nowrap);

    margin-bottom: 25px;
  }

  .fileTypeSelectBlock {
    margin-right: 16px;
  }
}

.dateAndFilterWrapper {
  @include flex(row, flex-start, center, nowrap);

  .clearFiltersBtn {
    @include btnReset();
    @include flex(row, center, center, nowrap);

    font-weight: 700;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: -0.777778px;
    color: $turtleGreen;
    padding: 10px;
    transition: all $timeFast ease-in-out;
    margin-right: 24px;

    &:hover {
      text-decoration: underline;
    }
  }

  .filterBtn {
    min-width: 113px;
    border: 2px solid $black42;
    border-radius: 10px;
    padding: 0 38px;
  }
}
