@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.formRow {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  .formRowLabel {
    @include fontVerlag(15px, 400);
    color: $black;
    letter-spacing: -0.778px;
  }
}
