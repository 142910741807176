@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.contentWrapper {
  @include flex(column, flex-start, center, nowrap);

  color: $titleBlack;
  min-width: 400px;

  .titleBlock {
    @include flex(column, flex-start, center, nowrap);

    position: relative;
    margin-bottom: 98px;

    .innerTitleBlock {
      margin-bottom: 0;
    }
  }

  .form {
    width: 100%;
    margin-bottom: 12px;

    @include flex(column, flex-start, center, nowrap);

    .emailFieldWrapper {
      margin-bottom: 85px;
    }

    .passwordFieldWrapper {
      margin-bottom: 100px;
    }
  }

  .passwordLink {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $titleBlack;
    transition: all $timeFast ease-in-out;

    &:hover {
      text-decoration: underline;
    }
  }
}
