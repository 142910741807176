@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.iconWrapper {
  @include flex(row, center, center, nowrap);
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.iconStick {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 2px;
  height: 11px;
  background-color: $turtleGreen;
  border-radius: 0.5px;
  transition: all $timeFast ease-in-out;
}

.iconHorizontalStick {
  transform: translate(-50%, -50%) rotate(90deg);
}

.iconWrapperActive {
  .iconVerticalStick {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
