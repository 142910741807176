@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.collapsibleRoot {
  min-height: 46px;
  background-color: rgba(7, 113, 134, 0.04);

  &:nth-of-type(odd) {
    box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.05);
    background: $white;
  }

  .collapsibleCellRoot {
    font-family: 'Verlag', sans-serif;
    font-size: 15px;
    color: $black;
    text-transform: capitalize;
    white-space: nowrap;
    height: 46px;
    max-height: 46px;
    padding: 12px 6px;
    border: none;
  }

  .cellLinkRoot {
    padding: 0;
  }
}

.detailsPathLink {
  @include flex(row, center, center, nowrap);
  min-height: 46px;
  padding: 12px 6px;
  color: $black;
}

.collapsibleRootHighlighted {
  cursor: pointer;

  &:hover {
    position: relative;
    box-shadow: inset 0px 0px 5px 1px rgba($turtleGreen, 0.25);
  }
}

.collapseContentWrapper {
  max-width: 100%;
  width: 100%;

  border: none;
  box-shadow: inset 0px 2px 10px rgb(0 0 0 / 5%);
  min-height: 184px;
  background-color: $white;

  @include flex(row, center, center, nowrap);
}

.modalDetailsPaper {
  @include flex(row, center, center, nowrap);
  padding: 24px;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  min-width: 600px;
}
