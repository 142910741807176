@import './src/assets/styles/variables.scss';
@import './src/assets/styles/mixins.scss';

.dashboard {
  .portfolioStructureWrapper {
    margin: 16px 0 0;
  }
}

.contentHeadingBox {
  padding: 10px 30px;

  .headingContent {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
  }
}

.portfolioAllocationBox {
  @include flex(column, flex-start, flex-end, nowrap);

  .refreshBtnBox {
    @include flex(row, flex-end, center, nowrap);
  }
}

.blocks {
  @include flex(column, flex-start, flex-start, nowrap);
  height: 100%;
}

.block {
  background-color: $white;
  border-radius: 0.75rem;
  padding: 28px 24px;
  width: 100%;

  &_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_title {
    font-weight: 325;
    font-size: 1.75rem;
    line-height: 2rem;
    letter-spacing: -0.777778px;
  }
}

.returnIndexBlock {
  margin-bottom: 16px;
}

.unusedAmountBlock {
  @include flex(column, flex-start, center, nowrap);
  height: 100%;

  .unusedAmountBox {
    @include flex(column, center, center, nowrap);
    height: 100%;
  }

  .unusedAmountValue {
    @include fontVerlag(40px, 500);
    white-space: nowrap;
  }
}

.mapBlock {
  display: flex;
  background-color: $white;
  border-radius: 0.75rem;
  padding: 18px 20px;
  height: 100%;
}

.benefits {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  margin-top: 1rem;
  &_item {
    text-align: center;
    &_value {
      font-size: 1.75rem;
      white-space: nowrap;
    }
    &_label {
      font-size: 1rem;
    }
  }
}

.widgets {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: start;
  gap: 1rem;
  margin-top: 1rem;

  .widgetsTitle {
    width: 100%;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      padding-top: 0.75rem;
      border-bottom: 2px solid $softWhite;
    }
  }

  .widgetsReturnIndexBox {
    @include flex(column, flex-start, center, nowrap);
    margin-top: 1rem;
    row-gap: 2.25rem;
  }
}
.map {
  display: block;
}

.statistic {
  display: block;
}

.noReport {
  text-align: center;
  padding: 2em 0;
  font-size: 2em;
}

.loaderWrapper {
  text-align: center;
  padding: 3em 0;
}
